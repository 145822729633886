import {useEffect} from 'react';
import {Button} from '../../../../Components/BasicComponents/BasicComponents';
import stylesD from './PayNotifyD.module.css';
import stylesM from './PayNotifyM.module.css';
import {usePlanBlock} from '../../../../store';
import {useDB} from 'utils/DB';
import {useTranslation} from 'react-i18next';

export function PayNotify(props) {
  const { t } = useTranslation();
  let { specialist } = useDB();
  let { expiresIn, getData } = usePlanBlock();

  useEffect(() => {
    async function af() {
      getData();
    }
    af();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialist]);

  if (new URL(window.location.href).pathname === '/payments') {
    return;
  }

  if (expiresIn < Date.now()) {
    if (props.type === 'mobile') {
      return (
        <>
          <div className={stylesM.notification_container}>
            {t('renew_license')} &nbsp;&nbsp;
            <span>
              {' '}
              <Button
                onClick={() => {
                  window.location.href = '/payments';
                }}
                style={{
                  backgroundColor: 'white',
                  color: 'coral',
                  height: '40px',
                  width: '150px',
                  fontSize: '16px',
                }}
                name={t('renew')}
              />
            </span>
          </div>
        </>
      );
    }

    return (
      <>
        <div className={stylesD.notification_container}>
          {t('renew_license')} &nbsp;&nbsp;
          <span>
            {' '}
            <Button
              onClick={() => {
                window.location.href = '/payments';
              }}
              style={{
                backgroundColor: 'white',
                color: 'coral',
                height: '40px',
                width: '150px',
                fontSize: '16px',
              }}
              name={t('renew') }
            />
          </span>
        </div>
      </>
    );
  }
}