import { create } from 'zustand';
import axios from 'axios';
import { checkAccess } from './access';
import moment from 'moment';
import { useDB } from 'utils/DB';
import { API } from 'utils/api';
import { toastUtility } from 'utils/toast.utility.js';

export let useGlobalStore = create((set, get) => ({
  appointments: [],

  getSpecialistAppointments: async () => {
    let { data } = await axios.get(
      `${process.env.REACT_APP_API}/api/specialists/${checkAccess()}/appointments`,
    );
    console.log(data);
    set({ appointments: data });
    return data;
  },
}));

//* NotificationsStore

export let useNotificationsStore = create((set, get) => ({
  notificationsStatus: {
    telegram: false,
    push: false,
    email: false,
    sms: false,
  },
  clientSMSNotifications: undefined,
  recordsNotifications: {
    total: 0,
    notifications: []
  },
  notificationsExists: false,

  updateNotificationsExists: async (notificationsExists = undefined) => {
    let { data } = await axios.get(
      `${process.env.REACT_APP_API}/api/specialist/${checkAccess()}/notifications-exists`,
      {
        params: {
          notificationsExists
        },
      }
    );

    set({ notificationsExists: data.notificationsExists });
  },

  getRecordsNotificationsList: async (offset = 0, limit = 10) => {
    let { data } = await axios.get(
      `${process.env.REACT_APP_API}/api/specialist/notifications-message`,
      {
        params: {
          specialistId: checkAccess(),
          offset,
          limit,
        },
      }
    );

    set({
      recordsNotifications: {
        total: data.total,
        notifications: [...get().recordsNotifications.notifications ?? [], ...data.notifications]
      }
    });
  },

  sendRecordsNotifications: async (message) => {
    let { data } = await axios.post(
      `${process.env.REACT_APP_API}/api/specialist/notifications-message`,
      {
        body: {
          specialistId: checkAccess(),
          message
        },
      }
    );

    set({
      recordsNotifications: {
        total: data.total,
        notifications: [...get().recordsNotifications.notifications ?? [], ...data.notifications]
      }
    });
  },

  getNotificationsStatus: async () => {
    let { data } = await axios.get(
      `${process.env.REACT_APP_API}/api/specialists/${checkAccess()}`,
      { withCredentials: true },
    );
    set({ notificationsStatus: data.notifications });

    let clientSMSNotifications =
      await API.Specialist.getClientSMSNotificationsSettings();

    if (clientSMSNotifications) set({ clientSMSNotifications });
  },

  clientSMSNotificationsHandler: async (type, v) => {
    set((state) => {
      state.clientSMSNotifications[type] = v;
      return { clientSMSNotifications: state.clientSMSNotifications };
    });
  },

  saveClientSMSNotificationsSettings: async () => {
    toastUtility.showSuccess('Налаштування збережено');

    const data = await API.Specialist.editClientSMSNotificationsSettings(
      get().clientSMSNotifications,
    );
    if (data) {
      // set({clientSMSNotifications:data})
    }
  },

  changeTelegramStatus: async () => {
    let telegramStatus =
      useNotificationsStore.getState().notificationsStatus.telegram;

    let { data } = await axios.get(
      `${process.env.REACT_APP_API}/api/specialists/${checkAccess()}`,
      { withCredentials: true },
    );
    let specialist = data;

    if (specialist.tgchat == 0) {
      usePopUpTelegramStore.getState().showPopUpTelegram();
      return;
    }

    if (telegramStatus) {
      specialist.notifications.telegram = false;
      let { data } = await axios.put(
        `${process.env.REACT_APP_API}/api/specialists/${checkAccess()}`,
        specialist,
        { withCredentials: true },
      );
      set((state) => {
        state.notificationsStatus.telegram = false;
        return { notificationsStatus: state.notificationsStatus };
      });
    }

    if (!telegramStatus) {
      specialist.notifications.telegram = true;
      let { data } = await axios.put(
        `${process.env.REACT_APP_API}/api/specialists/${checkAccess()}`,
        specialist,
        { withCredentials: true },
      );
      set((state) => {
        state.notificationsStatus.telegram = true;
        return { notificationsStatus: state.notificationsStatus };
      });
    }
  },

  changeEmailStatus: async () => {
    let emailStatus =
      useNotificationsStore.getState().notificationsStatus.email;

    let { data } = await axios.get(
      `${process.env.REACT_APP_API}/api/specialists/${checkAccess()}`,
      { withCredentials: true },
    );
    let specialist = data;

    if (emailStatus) {
      specialist.notifications.email = false;
      let { data } = await axios.put(
        `${process.env.REACT_APP_API}/api/specialists/${checkAccess()}`,
        specialist,
        { withCredentials: true },
      );
      set((state) => {
        state.notificationsStatus.email = false;
        return { notificationsStatus: state.notificationsStatus };
      });
    }

    if (!emailStatus) {
      specialist.notifications.email = true;
      let { data } = await axios.put(
        `${process.env.REACT_APP_API}/api/specialists/${checkAccess()}`,
        specialist,
        { withCredentials: true },
      );
      set((state) => {
        state.notificationsStatus.email = true;
        return { notificationsStatus: state.notificationsStatus };
      });
    }
  },

  changePushStatus: async () => {
    let pushStatus = useNotificationsStore.getState().notificationsStatus.push;

    let { data } = await axios.get(
      `${process.env.REACT_APP_API}/api/specialists/${checkAccess()}`,
      { withCredentials: true },
    );
    let specialist = data;

    if (pushStatus) {
      specialist.notifications.push = false;
      let { data } = await axios.put(
        `${process.env.REACT_APP_API}/api/specialists/${checkAccess()}`,
        specialist,
        { withCredentials: true },
      );
      set((state) => {
        state.notificationsStatus.push = false;
        return { notificationsStatus: state.notificationsStatus };
      });
    }

    if (!pushStatus) {
      if (window.ReactNativeWebView) {
        // send data object to React Native (only string)

        window.ReactNativeWebView.postMessage(JSON.stringify('getPermission'));
      }

      // specialist.notifications.push=true
      // let {data}=await axios.put(`${process.env.REACT_APP_API}/api/specialists/${checkAccess()}`,specialist,{withCredentials:true})
      // set(state=>{state.notificationsStatus.push=true;return {notificationsStatus:state.notificationsStatus}})
    }
  },

  changeSmsStatus: async () => {
    let smsStatus = useNotificationsStore.getState().notificationsStatus.sms;

    let { data } = await axios.get(
      `${process.env.REACT_APP_API}/api/specialists/${checkAccess()}`,
      { withCredentials: true },
    );
    let specialist = data;

    if (smsStatus) {
      specialist.notifications.sms = false;
      let { data } = await axios.put(
        `${process.env.REACT_APP_API}/api/specialists/${checkAccess()}`,
        specialist,
        { withCredentials: true },
      );
      set((state) => {
        state.notificationsStatus.sms = false;
        return { notificationsStatus: state.notificationsStatus };
      });
    }

    if (!smsStatus) {
      specialist.notifications.sms = true;
      let { data } = await axios.put(
        `${process.env.REACT_APP_API}/api/specialists/${checkAccess()}`,
        specialist,
        { withCredentials: true },
      );
      set((state) => {
        state.notificationsStatus.sms = true;
        return { notificationsStatus: state.notificationsStatus };
      });
    }
  },

  savePushToken: async (token) => {
    let { data } = await axios.get(
      `${process.env.REACT_APP_API}/api/specialists/${checkAccess()}`,
      { withCredentials: true },
    );
    let specialist = data;

    specialist.pushToken = token;
    specialist.notifications.push = true;

    //Изменить статус
    let response = await axios.put(
      `${process.env.REACT_APP_API}/api/specialists/${checkAccess()}`,
      specialist,
      { withCredentials: true },
    );
    set((state) => {
      state.notificationsStatus.push = true;
      return { notificationsStatus: state.notificationsStatus };
    });
  },
}));

//* PopUpTelegramStore

export let usePopUpTelegramStore = create((set) => ({
  visiable: false,
  showPopUpTelegram: () => {
    set({ visiable: true });
  },
  closePopUpTelegram: () => {
    set({ visiable: false });
  },

  screen: 1,
  changeScreen: (screen) => {
    set({ screen: screen });
  },
}));

//*SMSTopUpStore

export let useSMSTopUpStore = create((set, get) => ({
  balance: 0,

  topUpSMS: '30',  // По умолчанию выбран первый пакет
  priceForSelectedAmountOfSMS: 36,  // Цена за 30 SMS
  fee: (36 * 0.03).toFixed(2),  // Комиссия 3%
  sumToPay: (36 + 36 * 0.03).toFixed(2),  // Общая сумма

  currentPackageIndex: 0,
  smsPackages: [
    { count: 30, price: 36 },
    { count: 50, price: 60 },
    { count: 100, price: 120 },
    { count: 150, price: 180 },
    { count: 200, price: 240 },
    { count: 250, price: 300 },
    { count: 300, price: 360 },
  ],

  getSMSbalance: async () => {
    let { data } = await axios.get(
      `${process.env.REACT_APP_API}/api/specialists/${checkAccess()}`,
      { withCredentials: true },
    );
    set({ balance: data.smsBalance });
  },

  selectPackage: (index) => {
    const packages = get().smsPackages;
    const selectedPackage = packages[index];

    if (selectedPackage) {
      const fee = (selectedPackage.price * 0.03).toFixed(2);
      const sumToPay = (selectedPackage.price + parseFloat(fee)).toFixed(2);

      set({
        currentPackageIndex: index,
        topUpSMS: selectedPackage.count,
        priceForSelectedAmountOfSMS: selectedPackage.price,
        fee: fee,
        sumToPay: sumToPay,
      });
    }
  },

  incrementPackage: () => {
    const { currentPackageIndex, smsPackages } = get();
    console.log('currentPackageIndex < smsPackages.length - 1', currentPackageIndex < smsPackages.length - 1);

    if (currentPackageIndex < smsPackages.length - 1) {
      get().selectPackage(currentPackageIndex + 1);
    }
  },

  decrementPackage: () => {
    const { currentPackageIndex } = get();
    if (currentPackageIndex > 0) {
      get().selectPackage(currentPackageIndex - 1);
    }
  },

  createInvoice: async () => {
    if (!get().topUpSMS | (get().topUpSMS == 0)) {
      return;
    }
    let { data } = await axios.post(
      `${process.env.REACT_APP_API}/api/payments/sms`,
      { specialistId: checkAccess(), numberOfsms: get().topUpSMS },
      { withCredentials: true },
    );
    if (data.status) {
      window.open(data.pageUrl, '_self');
    }
    if (!data.status) {
      console.log('Помилка створення інвойсу');
    }
  },
}));

export let useTransactions = create((set, get) => ({
  transactions: [],
  getTransactions: async () => {
    let { data } = await axios.post(
      `${process.env.REACT_APP_API}/api/invoices`,
      { specialistId: checkAccess() },
      { withCredentials: true },
    );
    set({ transactions: data });
  },
}));

export let usePlanBlock = create((set, get) => ({
  plan: undefined,
  expiresIn: undefined,

  getData: async () => {
    let data = useDB.getState().specialist;

    if (data?.plan) {
      set({ plan: data.plan.plan });
      set({ expiresIn: +data.plan.expiresIn });
    }
  },
}));
