import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import stylesMobile from './SubscriptionPlanM.module.css';
import stylesDesktop from './SubscriptionPlanD.module.css';
import { Button } from 'Components/BasicComponents/BasicComponents';
import { usePlanBlock } from 'store';
import { usePaymentsStore } from 'Pages/Payments/PaymentsStore';

export function SubscriptionPlan({ onClick }) {
  const { t, i18n } = useTranslation();

  const { plan, expiresIn, getData } = usePlanBlock(); 
  const { tariffs } = usePaymentsStore(); 

  useEffect(() => {
    getData(); 
  }, []);


  if (!plan || !tariffs?.length) {
    return null
  }

  const isMobile = window.innerWidth <= 768; // определение мобильного устройства

  const containerStyle = isMobile ? stylesMobile.plan_block_container : stylesDesktop.plan_block_container;
  const planStyle = isMobile ? stylesMobile.plan : stylesDesktop.plan;
  const timeLeftStyle = isMobile ? stylesMobile.time_left : stylesDesktop.time_left;
  const buttonStyle = {
    backgroundColor: 'black',
    height: '46px',
    fontWeight: 'bold',
    cursor: 'pointer',
    ...(isMobile ? {} : { width: '231px' }),
  };

  const selectedPlan = tariffs.find(tariff => tariff.type === plan);
  const planeName = plan === 'trial' ? t('trial') : selectedPlan?.name[i18n.language]
  
  return (
    <div className={containerStyle}>
      <div className={planStyle}>
        {planeName} 
      </div>
      <div className={timeLeftStyle}>
        {t('valid_until')}: {moment(expiresIn).format('DD.MM.YYYY')}
      </div>
      <Button style={buttonStyle} name={t('select_tariff')} onClick={onClick} />
    </div>
  );
}
