import { create } from 'zustand';
import i18n from 'i18n';
import axios from 'axios';
import { useToast2 } from 'Components/Toast2/ToastStore';
import { checkAccess } from 'access';

const { showToast } = useToast2.getState();

export let useCasaStore = create((set, get) => ({
  isOpen: false,
  transactionCount: 0,
  totalAmount: 0,
  netProfit: 0,
  transactionsAll: [],
  transactions: [],
  activeTab: 'income', // income | expense
  period: [new Date(), new Date('2025-01-01')],

  recalculateTransactions: async (transactions) => {
    const transactionType = get().activeTab
    const transactionsOfType = transactions.filter((t) => t.transactionType === transactionType)
    const totalAmount = transactionsOfType.reduce((acc, next) => acc + next.amount, 0);
    const totalProfit = transactions.reduce(
      ({ income, expense }, { transactionType, amount }) => ({
        income: transactionType === "income" ? income + amount : income,
        expense: transactionType === "expense" ? expense + amount : expense,
      }),
      { income: 0, expense: 0 }
    );

    set({
      transactions: transactionsOfType,
      transactionCount: transactionsOfType.length,
      totalAmount: totalAmount,
      netProfit: totalProfit.income - totalProfit.expense
    })
  },

  getTransactions: async (currentWeek) => {
    try {
      if (!currentWeek) return

      const startDate = currentWeek[0]
      const endDate = currentWeek[1]

      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/transaction/${checkAccess()}`,
        {
          params: {
            startDate: startDate,
            endDate: endDate
          }
        }
      );

      get().recalculateTransactions(data.transactions)
      set({ transactionsAll: data.transactions })
    } catch (error) {
      console.error(error);
      showToast('e', error.response?.data?.message ?? i18n.t('Error!'));

      if (error.response.status === 403) {
        window.location.href = '/profile/specialist'
      }
    }
  },

  setActiveTab: (activeTab) => {
    set({ activeTab })
  },

  calendarHandler: (value) => {
    set({ period: value });
    get().getTransactions(value)
  },
}));

