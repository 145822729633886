import React, { useEffect } from 'react';
import styles from './CashModals.module.css';
import Imgs from '../../../../Imgs/Imgs';
import { useTranslation } from 'react-i18next';
import { Modal } from 'Components/Modal/Modal';
import {useCashModalsStore} from './CashModalsStore';
import {Input, Button} from 'Components/BasicComponents/BasicComponents';

function CashModals(props) {
  const { t } = useTranslation();
  const { 
    settingsWindow, cashWindow, expenseWindow, incomeWindow, 
    handleSettingsWindow, handleCashWindow, handleIncomeWindow,
    handleExpenseWindow, addCategory, handleCategory,
    deleteCategory, hasChanged, saveCategoriesSettings,
    getCategoriesSettings, settings
} = useCashModalsStore();

  useEffect(() => {
    getCategoriesSettings()
  }, []);
    
  if (incomeWindow) {
    return (
      <Modal className={styles.popup_window} onClose={() => handleSettingsWindow(false)} isOpen={settingsWindow}>
        <div className={styles.titleBlock}>
          <h3 className={styles.title}>{t('settings')}</h3>
          <span className={styles.close} onClick={() => handleSettingsWindow(false)}> <Imgs img={'close'} /> </span>
        </div>

        <div onClick={() => handleIncomeWindow(false)} className={styles.back}>
          <Imgs width={'30px'} img={'back_arrow'} /> {t('fin_income_categories')}
        </div>

        {settings.categoriesIncome.map((category) => (
          <div key={category.value} className={styles.category_container}>
            <Input
              id={category.value}
              placeholder={t('category_name')}
              value={category.label}
              onChange={(e) => handleCategory(e, 'categoriesIncome')}
              style={{ maxWidth: '320px'}}
            />
            { category.value !== 1 && <Imgs img={'delete'} width={'30px'} onClick={() => deleteCategory(category.value, 'categoriesIncome')} />}
          </div>
        ))}

        <span className={styles.addCategory} onClick={() => addCategory('categoriesIncome')}>+{t('fin_add_category')}</span>

        {
          hasChanged && (
            <Button
              name={t('Зберегти')}
              style={{ marginTop: 15}}
              onClick={() => saveCategoriesSettings('categoriesIncome')}
            />
          )
        }
      </Modal> 
    );
  }

  if (expenseWindow) {
    return (
      <Modal className={styles.popup_window} onClose={() => handleSettingsWindow(false)} isOpen={settingsWindow}>
        <div className={styles.titleBlock}>
          <h3 className={styles.title}>{t('settings')}</h3>
          <span className={styles.close} onClick={() => handleSettingsWindow(false)}> <Imgs img={'close'} /> </span>
        </div>

        <div onClick={() => handleExpenseWindow(false)} className={styles.back}>
          <Imgs width={'30px'} img={'back_arrow'} /> {t('fin_expense_categories')}
        </div>

        {settings.categoriesExpense.map((category) => (
          <div key={category.value} className={styles.category_container}>
            <Input
              id={category.value}
              placeholder={t('category_name')}
              value={category.label}
              onChange={(e) => handleCategory(e, 'categoriesExpense')}
              style={{ maxWidth: '320px'}}
            />
            <Imgs img={'delete'} width={'30px'} onClick={() => deleteCategory(category.value, 'categoriesExpense')} />
          </div>
        ))}

        <span className={styles.addCategory} onClick={() => addCategory('categoriesExpense')}>+ {t('fin_add_category')}</span>

        {
          hasChanged && (
            <Button
              name={t('Зберегти')}
              style={{ marginTop: 15}}
              onClick={() => saveCategoriesSettings('categoriesExpense')}
            />
          )
        }
      </Modal> 
    );
  }

  if (cashWindow) {
    return (
      <Modal className={styles.popup_window} onClose={() => handleSettingsWindow(false)} isOpen={settingsWindow}>
        <div className={styles.titleBlock}>
          <h3 className={styles.title}>{t('fin_settings')}</h3>
          <span className={styles.close} onClick={() => handleSettingsWindow(false)}> <Imgs img={'close'} /> </span>
        </div>

        <div onClick={() => handleCashWindow(false)} className={styles.back}>
          <Imgs width={'30px'} img={'back_arrow'} /> {t('fin_cash_register')}
        </div>

        {settings.paymentMethods.map((category) => (
          <div key={category.value} className={styles.category_container}>
            <Input
              id={category.value}
              placeholder={t('category_name')}
              value={category.label}
              onChange={(e) => handleCategory(e, 'paymentMethods')}
              style={{ maxWidth: '320px'}}
            />
            <Imgs img={'delete'} width={'30px'} onClick={() => deleteCategory(category.value, 'paymentMethods')} />
          </div>
        ))}

        <span className={styles.addCategory} onClick={() => addCategory('paymentMethods')}>+ {t('fin_cash_flow_source')}</span>

        {
          hasChanged && (
            <Button
              name={t('Зберегти')}
              style={{ marginTop: 15}}
              onClick={() => saveCategoriesSettings('paymentMethods')}
            />
          )
        }
      </Modal> 
    );
  }

  return (
      <Modal className={styles.popup_window} onClose={() => handleSettingsWindow(false)} isOpen={settingsWindow}>
        <div className={styles.titleBlock}>
          <h3 className={styles.title}>{t('settings')}</h3>
          <span className={styles.close} onClick={() => handleSettingsWindow(false)}> <Imgs img={'close'} /> </span>
        </div>

        <button className={styles.navigateButton} onClick={() => handleCashWindow(true)}>{t('fin_cash')}</button>
        <button className={styles.navigateButton} onClick={() => handleIncomeWindow(true)}>{t('fin_income_categories')}</button>
        <button className={styles.navigateButton} onClick={() => handleExpenseWindow(true)}>{t('fin_expense_categories')}</button>
      </Modal> 
    );
}

export default CashModals;
