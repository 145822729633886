import { create } from 'zustand';
import { API } from 'utils/api';
import { useClientsStore } from 'Pages/Clients/ClientsStore';
import { useAppointmentsStore } from '../AppointmentsBlock/AppointmentsStore';

export let useSelectClient = create((set, get) => ({
  client: undefined,
  clients: [],
  clientsOptions: [],
  clientSearchInputValue: undefined,

  source: undefined,

  getSpecialistClients: async () => {
    let data = await API.Client.getAll();
    let clientsOptions = data.map((client) => {
      return {
        value: client._id,
        label: `${client.name} ${client.surname}`,
        phone: client.phone,
      };
    });
    clientsOptions = [
      { value: 'addNew', label: '+ Додати нового', color: '#FF8A35' },
      ...clientsOptions,
    ];
    set({ clients: data, clientsOptions: clientsOptions });
  },

  clientSearchInputHandler: (data) => {
    if (data.value == 'addNew') {
      set({ state: 'button' });

      useAppointmentsStore.getState().hideAddAppointmentWindow();
      useAppointmentsStore.getState().hideEditAppointmentWindow();
      useClientsStore.getState().showAddContactForm(get().source);

      return;
    }

    set({
      client: { name: data.label, phone: data.phone },
      clientSearchInputValue: data,
    });
  },

  setInitialClient: (clientPhone) => {
    if (!clientPhone) {
      return;
    }

    let client = get().clients.find((client) => client.phone == clientPhone);
    if (!client) {
      return;
    }
    set({ clientSearchInputValue: { value: client.name, label: client.name } });
  },

  setSource: (source) => {
    set({ source: source });
  },
}));
