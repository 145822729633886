import { usePlanBlock } from "store";
import { toastUtility } from "./toast.utility";

export const TARIFFS = {
  TRIAL: 'trial',
  BASIC: 'base',
  PREMIUM: 'premium',
};

/**
 * Перевірка тарифного плану користувача.
 * @param {Array<string>} allowedTariffs - Список тарифів, яким дозволено виконувати дію.
 * @param {Function|null} action - Дія, яку потрібно виконати, якщо тариф дозволяє.
 * @param {Object} options - Налаштування для перевірки.
 * @param {string} options.text - Повідомлення для відображення, якщо тариф не дозволяє виконати дію.
 * @param {boolean} options.hasBoolean - Флаг для повернення логічного значення замість виконання дії.
 * @returns {Function} - Функція-обгортка для перевірки тарифу.
 */
export function withTariffGuard(
  allowedTariffs,
  action = null,
  options = {
    text: "Ваш тариф не дозволяє виконати цю дію.",
    hasBoolean: false,
  }
) {
  return () => {
    const userTariff = usePlanBlock.getState().plan;
    const isForbidden = !allowedTariffs.includes(userTariff)

    if (options.hasBoolean) {
      return isForbidden;
    }

    if (isForbidden) {
      toastUtility.showError(options.text);
      return false;
    }

    if (typeof action === 'function') {
      action();
    }
  };
}