import { fn } from 'moment/moment';
import React from 'react';
import { BlockTitle, Button } from '../LandingComponents';
import styles from './Prices.module.css';
import stylesM from './PricesM.module.css';
import { useTranslation } from 'react-i18next';
import {usePaymentsStore} from '../../../Payments/PaymentsStore';

function Plan(props) {
  const { t } = useTranslation();

  const {
    title,
    price,
    options,
    buttonText,
    onClick
  } = props

  if (props.type == 'm') {
    return (
      <div className={stylesM.plan_container}>
        <div className={stylesM.plan_title}>{title}</div>
        <div
          className={stylesM.options}
          style={{ fonSize: '18px', color: 'grey' }}
        >
          {' '}
          <span style={{ fontSize: '35px', fontWeight: 500, color: 'black' }}>
            {price}
          </span>
          {` ${t('uah_per_month')}`}
        </div>
        <div className={stylesM.options}>
          {options.map((option, index) => (
            <div key={index}><p >· {option}</p> {index + 1 !== options.length ? <br/> : null} </div>
          ))}
        </div>
        <Button
          type={4}
          name={t(buttonText)}
          style={{
            width: '280px',
            height: '60px',
            marginTop: 'auto',
            position: 'relative',
          }}
          onClick={onClick}
        />
      </div>
    );
  }

  return (
    <div className={styles.plan_container}>
      <div className={styles.plan_title}>
        {title}
      </div>
      <div
        className={styles.options}
        style={{ fonSize: '18px', color: 'grey' }}
      >
        {' '}
        <span style={{ fontSize: '35px', fontWeight: 500, color: 'black' }}>
          {price}
        </span>
        {` ${t('uah_per_month')}`}
      </div>
      <div className={styles.options}>
        {options.map((option, index) => (
          <div key={index}><p >· {option}</p> {index + 1 !== options.length ? <br/> : null} </div>
        ))}
      </div>
      <Button
        type={4}
        name={t(buttonText)}
        style={{ width: '370px', height: '60px', marginTop: 'auto' }}
      />
    </div>
  );
}

export function Prices(props) {
  const { t, i18n } = useTranslation();
  const {
    tariffs,
    getTariffPlans,
  } = usePaymentsStore()
  
  const selectPlane = (tariffId) => {
    // setCurrentTariff(tariffId)
    window.location.href = '/signup';
  };

  React.useEffect(() => {
    getTariffPlans()
  }, []);

  if (props.type == 'm') {
    return (
      <>
        {/* <div className={styles.title_container}><BlockTitle type={'m'} title={'Тарифи'} /></div> */}
        <div className={stylesM.container}>
          <BlockTitle title={t('tariffs')} type={'m'} />
          {tariffs.map((tariff) => {
          const planOptions = tariff.planOptions.map((option) => option.name[i18n.language])
          
          return (
            <Plan
              key={tariff._id}
              type="m"
              title={tariff.name[i18n.language]}
              price={tariff.price}
              options={planOptions}
              buttonText="try"
              onClick={() => selectPlane(tariff._id)}
            />
          )
        })}
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.title_container}>
        <BlockTitle title={t('tariffs')} />
      </div>
      <div className={styles.container}>
        {tariffs.map((tariff) => {
          const planOptions = tariff.planOptions.map((option) => option.name[i18n.language])
          
          return (
            <Plan
              key={tariff._id}
              title={tariff.name[i18n.language]}
              price={tariff.price}
              options={planOptions}
              buttonText="try"
              onClick={() => selectPlane(tariff._id)}
            />
          )
        })}
      </div>
    </>
  );
}
