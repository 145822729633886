import React from 'react';
import { Button } from '../LandingComponents';
import styles from './Footer.module.css';
import stylesM from './FooterM.module.css';

import telegram from '../../Images/messengers/telegram.svg';
import viber from '../../Images/messengers/viber.svg';
import whatsapp from '../../Images/messengers/whatsapp.svg';
import instagram from '../../Images/Instagram.png';
import facebook from '../../Images/Facebook.png';
import a from '../../Images/a.png';
import g from '../../Images/g.png';
import { useTranslation } from 'react-i18next';

function PaymentSystems(props) {
  if (props.type == 'visa') {
    return (
      <svg
        width="78"
        height="25"
        viewBox="0 0 78 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.3914 0.441879L19.2568 24.6218H12.6447L7.65748 5.32512C7.3547 4.13656 7.09143 3.70114 6.17058 3.20039C4.66714 2.38477 2.1844 1.61955 0 1.14461L0.148368 0.441879H10.7918C12.1484 0.441879 13.368 1.34499 13.6761 2.90729L16.31 16.899L22.8196 0.441476H29.3914V0.441879ZM55.2985 16.7273C55.3251 10.3454 46.4739 9.99387 46.5347 7.14303C46.5537 6.2754 47.3798 5.35294 49.188 5.11749C50.0843 5.00016 52.5537 4.91066 55.3546 6.1996L56.4532 1.07204C54.9482 0.525737 53.0117 0 50.6024 0C44.4197 0 40.0686 3.28667 40.0319 7.99292C39.992 11.4739 43.1376 13.4164 45.5074 14.5731C47.9454 15.7576 48.7635 16.5188 48.7542 17.5784C48.7369 19.2003 46.8097 19.9164 45.0087 19.9446C41.8648 19.993 40.0404 19.0939 38.5862 18.4178L37.4524 23.7151C38.9139 24.3856 41.6116 24.9706 44.4084 25C50.9797 25 55.2784 21.7541 55.2985 16.7273ZM71.6247 24.6218H77.4098L72.36 0.441879H67.0204C65.8198 0.441879 64.807 1.14098 64.3587 2.21584L54.9724 24.6218H61.5405L62.8443 21.0102H70.8695L71.6247 24.6218ZM64.6453 16.0544L67.9376 6.97571L69.8325 16.0544H64.6453ZM38.3285 0.441879L33.1562 24.6218H26.9014L32.0757 0.441879H38.3285V0.441879Z"
          fill="white"
        />
      </svg>
    );
  }
  if (props.type == 'mc') {
    return (
      <svg
        width="48"
        height="30"
        viewBox="0 0 156 96"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M98.6623 10.2624H56.6624V85.7374H98.6623V10.2624Z"
          fill="#FF5F00"
        />
        <path
          d="M59.3294 47.9999C59.3227 40.7311 60.9699 33.5561 64.1461 27.018C67.3224 20.4799 71.9445 14.7501 77.6625 10.2624C70.5815 4.69659 62.0775 1.2353 53.1223 0.274141C44.1672 -0.687021 35.1223 0.890695 27.0215 4.82703C18.9206 8.76336 12.0907 14.8995 7.31232 22.534C2.53396 30.1685 0 38.9934 0 47.9999C0 57.0065 2.53396 65.8314 7.31232 73.4659C12.0907 81.1004 18.9206 87.2365 27.0215 91.1728C35.1223 95.1091 44.1672 96.6869 53.1223 95.7257C62.0775 94.7645 70.5815 91.3033 77.6625 85.7374C71.9445 81.2497 67.3225 75.5199 64.1462 68.9818C60.97 62.4437 59.3228 55.2687 59.3294 47.9999V47.9999Z"
          fill="#EB001B"
        />
        <path
          d="M155.323 47.9999C155.323 57.0064 152.789 65.8312 148.011 73.4657C143.233 81.1002 136.403 87.2363 128.303 91.1727C120.202 95.1091 111.157 96.6868 102.202 95.7257C93.2472 94.7645 84.7433 91.3032 77.6625 85.7374C83.3755 81.2452 87.994 75.5145 91.1697 68.9774C94.3454 62.4403 95.9955 55.2676 95.9955 47.9999C95.9955 40.7323 94.3454 33.5595 91.1697 27.0224C87.994 20.4853 83.3755 14.7546 77.6625 10.2624C84.7433 4.69657 93.2472 1.23528 102.202 0.274128C111.157 -0.687022 120.202 0.890764 128.303 4.82714C136.403 8.76351 143.233 14.8996 148.011 22.5341C152.789 30.1686 155.323 38.9935 155.323 47.9999V47.9999Z"
          fill="#F79E1B"
        />
        <path
          d="M150.743 77.7434V76.1982H151.366V75.8834H149.779V76.1982H150.403V77.7434H150.743ZM153.824 77.7434V75.8804H153.337L152.778 77.1618L152.218 75.8804H151.732V77.7434H152.075V76.3381L152.6 77.5497H152.956L153.481 76.335V77.7434H153.824Z"
          fill="#F79E1B"
        />
      </svg>
    );
  }
}

function Footer(props) {
  const { t } = useTranslation();

  if (props.type == 'm') {
    return (
      <>
        <div className={stylesM.container}>
          <div className={stylesM.text}>
            <div style={{ fontSize: '24px', marginBottom: '15px' }}>
              {t('info')}
            </div>
            <div
              onClick={() => {
                window.location.href = '/privacy-policy';
              }}
            >
              {t('privacy_policy')}
            </div>
            <div
              onClick={() => {
                window.location.href = '/license';
              }}
            >
              {t('license_agreement')}
            </div>
            <div
              onClick={() => {
                window.location.href = '/terms';
              }}
            >
              {t('terms_of_use')}
            </div>
          </div>

          <div className={stylesM.contact_with_messenger}>
            {t('contact_with_messenger')}
          </div>
          <div className={stylesM.messengers}>
            <img
              onClick={() => {
                window.open('https://t.me/planguin', '_blank');
              }}
              src={telegram}
              alt="telegram planguin"
            />
            <img
              onClick={() => {
                window.open('viber://chat?number=%2B380937520309', '_blank');
              }}
              src={viber}
              alt="viber planguin"
            />
            <img
              onClick={() => {
                window.open('https://wa.me/+380937520309', '_blank');
              }}
              src={whatsapp}
              alt="whatsapp planguin"
            />
          </div>
          <div
            onClick={() => {
              window.location.href = 'mailto:support@planguin.co';
            }}
            className={stylesM.mail}
          >
            {t('email')}: support@planguin.co
          </div>
          <div className={stylesM.social_links}>
            <img
              onClick={() => {
                window.open('https://instagram.com/planguin.co', '_blank');
              }}
              src={instagram}
              alt="instagram planguin"
            />
            <img
              onClick={() => {
                window.open('https://www.facebook.com/planguin.co', '_blank');
              }}
              src={facebook}
              alt="facebook planguin"
            />
          </div>
        </div>
        <div className={stylesM.bottom_line}>
          {new Date().getFullYear()} Planguin
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.left_side}>
          <div style={{ fontSize: '24px', marginBottom: '35px' }}>
            {t('info')}
          </div>
          <div
            onClick={() => {
              window.location.href = '/privacy-policy';
            }}
          >
            {t('privacy_policy')}
          </div>
          <div
            onClick={() => {
              window.location.href = '/license';
            }}
          >
            {t('license_agreement')}
          </div>
          <div
            onClick={() => {
              window.location.href = '/terms';
            }}
          >
            {t('terms_of_use')}
          </div>
        </div>
        <div className={styles.right_side}>
          <div>
            <div className={styles.contact_with_messenger}>
              {t('contact_with_messenger')}
            </div>
            <div className={styles.messengers}>
              <img
                onClick={() => {
                  window.open('https://t.me/planguin', '_blank');
                }}
                src={telegram}
                alt="telegram planguin"
              />
              <img
                onClick={() => {
                  window.open('viber://chat?number=%2B380937520309', '_blank');
                }}
                src={viber}
                alt="viber planguin"
              />
              <img
                onClick={() => {
                  window.open('https://wa.me/+380937520309', '_blank');
                }}
                src={whatsapp}
                alt="whatsapp planguin"
              />
            </div>
          </div>
          <div>
            <div
              onClick={() => {
                window.location.href = 'mailto:support@planguin.co';
              }}
              className={styles.mail}
            >
              {t('email')}: support@planguin.co
            </div>
            <div className={styles.social_links}>
              <img
                onClick={() => {
                  window.open('https://instagram.com/planguin.co', '_blank');
                }}
                src={instagram}
                alt="instagram planguin"
              />
              <img
                onClick={() => {
                  window.open('https://www.facebook.com/planguin.co', '_blank');
                }}
                src={facebook}
                alt="facebook planguin"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bottom_line}>
        {new Date().getFullYear()} Planguin
      </div>
    </>
  );
}

export default Footer;
