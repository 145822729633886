import React, { useState } from 'react';
import { checkAccess } from '../../access';
import Header, { MobileHeader } from '../../Components/Header/Header';
import {
  Button,
} from '../../Components/BasicComponents/BasicComponents';
import stylesD from './PaymentsD.module.css';
import stylesM from './PaymentsM.module.css';

import Imgs from '../../Imgs/Imgs';
import { useTranslation } from 'react-i18next';
import { LanguageEnum } from 'utils/enums';
import Transactions from './Components/Transactions/Transactions';
import { Plan } from './Components/Plans/Plans';
import { SMSTopUp } from './Components/SMSTopUp/SMSTopUp';
import { SubscriptionPlan } from './Components/SubscriptionPlan/SubscriptionPlan';
import {usePaymentsStore} from './PaymentsStore';
import {Modal} from '../../Components/Modal/Modal';
import {usePlanBlock} from 'store';

function Payments() {
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState('main');
  const specialistId = checkAccess();

  const {
    tariffs,
    currentTariff,
    setCurrentTariff,
    getTariffPlans,
    createInvoice,
    selected,
    setSelected,
    sumWithoutDiscount,
    discount,
    discountInUAH,
    total,
    isUpdatedTariffPlan,
    handleUpdatedTariffPlan
  } = usePaymentsStore()
  const { plan } = usePlanBlock()
  const currentName = i18n.language === LanguageEnum.Uk ? 'грн' : 'UAH';
  const isMobile = window.innerWidth < 1025

  const selectPlane = (tariffId) => {
    setCurrentTariff(tariffId)
    setPage('options')
  };

  const onPayment = () => {
    if (plan !== 'trial' && plan !== currentTariff.type) { 
      handleUpdatedTariffPlan(true)
    } else createInvoice()
  };

  React.useEffect(() => {
    document.body.style.backgroundColor = '#F4F7FE';

    getTariffPlans()

    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);

  const renderMobile = () => {
    switch (page) {
      case 'main':
        return (
          <>
            <MobileHeader title={t('payment')} type={2} />
            <div className={stylesM.main_container}>
              <SubscriptionPlan type="mobile" onClick={() => setPage('plans')} />
              <SMSTopUp type="mobile" />
              <Transactions type="mobile" />
            </div>
          </>
        );
      case 'plans':
        return (
          <>
            <Header platform="mobile" specialistId={specialistId} />
            <div className={stylesM.main_container}>
              <div className={stylesM.back_button_container}>
                <Imgs
                  onClickB={() => {
                    setPage('main');
                  }}
                  img={'back_arrow'}
                />
              </div>

              {tariffs.map((tariff) => {
                const planOptions = tariff.planOptions.map((option) => option.name[i18n.language])
                
                return (
                  <Plan
                    key={tariff._id}
                    title={tariff.name[i18n.language]}
                    price={tariff.price}
                    options={planOptions}
                    buttonText="activate"
                    onClick={() => selectPlane(tariff._id)}
                  />
                )
              })}
            </div>
          </>
        );
      case 'options':
        return (
          <>
            <Header platform="mobile" specialistId={specialistId} />
            <div className={stylesM.main_container}>
              <div className={stylesM.options_outer_container}>
              <div className={stylesM.options_container}>
                <div className={stylesM.options_title}>
                  <Imgs
                    img={'back_arrow'}
                    onClickB={() => {
                      setPage('plans');
                    }}
                  />{' '}
                  {currentTariff.name[i18n.language]}
                </div>
              
                <div className={stylesM.options_block}>
                  <div className={stylesM.options_block_title}>{t('payment_period')}</div>
                  {renderPeriodOptions()}
                </div>
                <div className={stylesM.options_block}>
                  <div className={stylesM.options_block_title}>{currentTariff.name[i18n.language]}</div>
                  {renderPricingDetails()}
                </div>
                <Button name={t('pay')} onClick={onPayment} />
                <div className={stylesM.conditions}>{renderConditions()}</div>
              </div>
            </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const renderDesktop = () => {
    switch (page) {
      case 'main':
        return (
          <>
            <Header platform="desktop" specialistId={specialistId} />
            <div className={stylesD.container}>
              <div className={stylesD.left_side}>
                <SubscriptionPlan onClick={() => setPage('plans')} />
                <Transactions />
              </div>
              <div className={stylesD.right}>
                <SMSTopUp />
              </div>
            </div>
          </>
        );
      case 'plans':
        return (
          <>
            <Header platform="desktop" specialistId={specialistId} />

            <div className={stylesD.back_button_container}>
              <div style={{ width: '837px' }}>
                <Imgs
                  onClickB={() => {
                    setPage('main');
                  }}
                  img={'back_arrow'}
                />
              </div>
            </div>
            <div className={stylesD.container}>
              {tariffs.map((tariff) => {
                const planOptions = tariff.planOptions.map((option) => option.name[i18n.language])
                
                return (
                  <Plan
                    key={tariff._id}
                    title={tariff.name[i18n.language]}
                    price={tariff.price}
                    options={planOptions}
                    buttonText="activate"
                    onClick={() => selectPlane(tariff._id)}
                  />
                )
              })}
            </div>
          </>
        );
      case 'options':
        return (
          <>
            <Header platform="desktop" specialistId={specialistId} />
            <div className={stylesD.options_outer_container}>
            <div className={stylesD.options_container}>
              <div className={stylesD.options_title}>
                <Imgs
                  img={'back_arrow'}
                  onClickB={() => {
                    setPage('plans');
                  }}
                />{' '}
                {currentTariff.name[i18n.language]}
              </div>
            
              <div className={stylesD.options_block}>
                <div className={stylesD.options_block_title}>{t('payment_period')}</div>
                {renderPeriodOptions()}
              </div>
              <div className={stylesD.options_block}>
                <div className={stylesD.options_block_title}>{currentTariff.name[i18n.language]}</div>
                {renderPricingDetails()}
              </div>
              <Button name={t('pay')} onClick={onPayment} />
              <div className={stylesD.conditions}>{renderConditions()}</div>
            </div>
          </div>
          </>
        );
      default:
        return null;
    }
  };

  const renderPeriodOptions = () =>
    currentTariff?.discounts.map((discount) => (
      <div key={discount.id} className={stylesD.option}>
        <input
          name="period"
          defaultChecked={discount.period === 1}
          value={discount.period}
          onClick={setSelected}
          id={discount.period}
          type="radio"
        />
        {discount.period} {t( discount.period > 1 ? 'months' : 'month')}
        {discount.period > 1 && <span className={stylesD.discount}> &nbsp;&nbsp;&nbsp; {t('discount')} {discount.name} </span>}
      </div>
    ));

  const renderPricingDetails = () => currentTariff && (
    <>
      <div className={stylesD.row}>
        <div>{`${currentTariff.price} ${currentName} х ${selected} ${t('months_short')}`}</div>
        <div style={{ fontWeight: 'bold' }}>{`${sumWithoutDiscount} ${currentName}`}</div>
      </div>
      <div className={stylesD.discount}>{t('discount')}</div>
      <div className={stylesD.row}>
        <div>{`${discount} % х ${selected} ${t('months_plural')}`}</div>
        <div style={{ fontWeight: 'bold' }}>-{`${discountInUAH} ${currentName}`}</div>
      </div>
      <div className={stylesD.row}>
        <div style={{ fontWeight: 'bold' }}>{t('total_amount_due')}:</div>
        <div style={{ fontWeight: 'bold' }}>
          <span style={{ color: '#FF8A35', textDecoration: 'line-through', fontWeight: '300' }}>
            {sumWithoutDiscount === currentTariff.price ? 0 : sumWithoutDiscount} {currentName}
          </span>{' '}
          {total} {currentName}
        </div>
      </div>
    </>
  );

  const renderConditions = () => (
    <>
      {`${t('i_agree_with')} `}
      <span onClick={() => window.open(`https://www.planguin.co/terms`)} style={{ textDecoration: 'underline' }}>
        {t('terms_of_service')}
      </span>{' '}
      {`${t('and')} `}
      <span onClick={() => window.open(`https://www.planguin.co/privacy-policy`)} style={{ textDecoration: 'underline' }}>
        {t('privacy_policy')}
      </span>
      {` ${t('subscription_starts_today')}`}
    </>
  );

  return <>
    {isMobile ? renderMobile() : renderDesktop()}
    <Modal className={stylesD.popup_window} onClose={() => handleUpdatedTariffPlan(false)} isOpen={isUpdatedTariffPlan}>
      <div
        className={stylesD.close_container}
        onClick={() => handleUpdatedTariffPlan(false)}
      >
        <Imgs img={'close'} />
      </div>
      <div className={stylesD.textInfo}>
        {t('update_tariffs_info')}
        <br/><br/>
        {t('renew')}?
      </div>
      <div className={stylesD.modal_buttons}>
        <Button
          onClick={createInvoice}
          name={t('yes')}
          style={{ marginTop: 20, height: '40px' }}
        />
        <Button
          onClick={() => handleUpdatedTariffPlan(false)}
          name={t('no')}
          color={'black'}
          style={{ marginTop: 20, height: '40px' }}
        />
      </div>
    </Modal>
  </> 
}

export default Payments;
