import { create } from 'zustand';
import axios from 'axios';
import moment from 'moment';
import i18n from 'i18n';
import 'moment/locale/de';
import 'moment/locale/ru';

export let useCalendarWeekChangerStore = create((set, get) => ({
  month: { month: '', year: '' },
  datesOfWeek: [],
  days: [],

  getMonthAndYearFromCurrentWeek: (currentWeek) => {
    if (!currentWeek) {
      return;
    }

    const firstDay = getFirstDayOfTheWeek(currentWeek);
    const lastDay = getLastDayOfTheWeek(currentWeek);
    const month = capitalizeFirstLetter(moment(firstDay).locale(i18n.language).format('MMMM'));
    const year = moment(firstDay).format('YYYY');
    console.log('getDatesBetween(firstDay, lastDay),', getDatesBetween(firstDay, lastDay),);

    set({
      month: { month, year },
      datesOfWeek: getDatesBetween(firstDay, lastDay),
    });
  },
}));

//Допоміжні

//Отримати дату початку неділі
function getFirstDayOfTheWeek(range) {
  let parts = range.split('-');
  return parts[0];
}

//Отримати дату кінця неділі
function getLastDayOfTheWeek(range) {
  let parts = range.split('-');
  return parts[1];
}

//Зробити першу літеру слова великою
function capitalizeFirstLetter(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

// Отримати масив дат з проміжка дат
function getDatesBetween(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const dates = [];

  let currentDate = start;
  while (currentDate <= end) {
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1; // getMonth() returns a zero-based value, so we need to add 1
    const year = currentDate.getFullYear();
    dates.push(moment(`${year}/${month}/${day}`).format('YYYY/MM/DD'));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
}
