import { useDB } from 'utils/DB';
import { checkAccess } from 'access';
import axios from 'axios';
import { useToast2 } from 'Components/Toast2/ToastStore';
let { showToast } = useToast2.getState();

export let API = {
  Specialist: {
    signUp: async (signUpForm) => {
      let { data } = await axios.post(
        `${process.env.REACT_APP_API}/api/specialist/signup`,
        signUpForm,
        { withCredentials: true },
      );

      return data;
    },

    login: async (data) => {
      console.log('login work', process.env.REACT_APP_API);
      let response = await axios.post(
        `${process.env.REACT_APP_API}/api/specialist/login`,
        data,
        { withCredentials: true },
      );
      return response;
    },

    getInfo: async () => {
      let { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/specialists/${checkAccess()}`,
        { withCredentials: true },
      );
      return data;
    },

    updateProfile: async (specialist) => {
      let { data } = await axios.put(
        `${process.env.REACT_APP_API}/api/specialists/${checkAccess()}`,
        specialist,
        { withCredentials: true },
      );

      if (data) {
        useDB.getState().updateDB('specialist');
      }

      return data;
    },

    changeLogin: async (login) => {
      let response = await axios.put(
        `${process.env.REACT_APP_API}/api/specialists/${checkAccess()}/login`,
        { login },
        { withCredentials: true },
      );

      if (response.data) {
        useDB.getState().updateDB('specialist');
      }

      return response;
    },

    updateSchedule: async (shedule) => {
      let { data } = await axios.put(
        `${process.env.REACT_APP_API}/api/shedule/`,
        { specialistId: checkAccess(), shedule },
        { withCredentials: true },
      );
      if (data) {
        useDB.getState().updateDB('specialist');
      }

      return data;
    },

    updatePhoto: async (imgURL) => {
      let { data } = await axios.put(
        `${process.env.REACT_APP_API}/api/specialists/${checkAccess()}`,
        { photo: imgURL },
        { withCredentials: true },
      );
      return data
    },

    getClientSMSNotificationsSettings: async () => {
      try {
        let { data } = await axios.get(
          `${
            process.env.REACT_APP_API
          }/api/specialists/${checkAccess()}/client-sms-notifications`,
          { withCredentials: true },
        );
        return data;
      } catch (error) {
        return false;
      }
    },

    editClientSMSNotificationsSettings: async (clientSMSNotifications) => {
      try {
        let { data } = await axios.put(
          `${
            process.env.REACT_APP_API
          }/api/specialists/${checkAccess()}/client-sms-notifications`,
          { clientSMSNotifications },
          { withCredentials: true },
        );
        return data;
      } catch (error) {
        return false;
      }
    },
  },
  Service: {
    getAll: async () => {
      let { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/specialist_services/${checkAccess()}`,
        { withCredentials: true },
      );
      return data;
    },
  },

  GroupService: {
    getAll: async () => {
      let { data } = await axios.get(
        `${
          process.env.REACT_APP_API
        }/api/specialist_group_services/${checkAccess()}`,
        { withCredentials: true },
      );
      return data;
    },

    getAllBySpecialistId: async ({ specialistId }) => {
      let { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/specialist_group_services/${specialistId}`,
        { withCredentials: true },
      );
      return data;
    },
  },

  Appointment: {
    getAll: async () => {
      let { data } = await axios.get(
        `${
          process.env.REACT_APP_API
        }/api/specialists/${checkAccess()}/appointments`,
        { withCredentials: true },
      );
      return data;
    },
  },

  GroupAppointment: {
    getAll: async () => {
      let { data } = await axios.get(
        `${
          process.env.REACT_APP_API
        }/api/specialists/${checkAccess()}/group_appointments`,
        { withCredentials: true },
      );
      return data;
    },

    saveMembers: async ({ groupAppointmentId, groupMembers }) => {
      let { data } = await axios.post(
        `${
          process.env.REACT_APP_API
        }/api/specialists/${checkAccess()}/group_appointments`,
        { groupAppointmentId, groupMembers },
        { withCredentials: true },
      );

      return data;
    },

    getTimeSlots: async ({ option, specialistId, date, groupServiceId }) => {
      let { data } = await axios.post(
        `${process.env.REACT_APP_API}/api/group_timeslots`,
        { option, specialistId, date, groupServiceId },
        { withCredentials: true },
      );
      return data;
    },

    addMember: async ({
      from,
      specialistId,
      groupAppointmentId,
      clientName,
      clientPhone,
    }) => {
      let { data } = await axios.put(
        `${process.env.REACT_APP_API}/api/specialists/group_appointments/addMember`,
        { from, specialistId, groupAppointmentId, clientName, clientPhone },
        { withCredentials: true },
      );

      return data;
    },

    deleteGroup: async (groupId, isSendSMS, isUser = false) => {
      let { data } = await axios.request({
        url: `${process.env.REACT_APP_API}/api/specialists/${groupId}/group_appointments`,
        method: 'delete',
        data: {
          groupId,
          isSendSMS,
          isUser
        }
      });
    
      return data;
    },
    cancelAppointmentGroup: async ({groupId, groupMembers, fullName}) => {
      let { data } = await axios.request({
        url: `${process.env.REACT_APP_API}/api/appointments/group/${groupId}/cancel`,
        method: 'delete',
        data: {
          groupId,
          groupMembers,
          fullName
        }
      });
    
      return data;
    },
  },

  Client: {
    getAll: async () => {
      let { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/clients/specialist/${checkAccess()}`,
        { withCredentials: true },
      );
      return data;
    },
  },

  Stats: {
    getSpecialistAppointmentsCount: async (from, to) => {
      let { data } = await axios.get(
        `${
          process.env.REACT_APP_API
        }/api/specialists/${checkAccess()}/appointments/count?from=${from}&to=${to}`,
        { withCredentials: true },
      );
      return data;
    },

    getServiceAppointmentsCount: async (from, to) => {
      let { data } = await axios.get(
        `${
          process.env.REACT_APP_API
        }/api/specialists/${checkAccess()}/serviceappointments/count?from=${from}&to=${to}`,
        { withCredentials: true },
      );
      return data;
    },

    getClientsCount: async () => {
      let { data } = await axios.get(
        `${
          process.env.REACT_APP_API
        }/api/specialists/${checkAccess()}/clients/count`,
        { withCredentials: true },
      );
      return data;
    },

    getNewClientsCount: async (from, to) => {
      let { data } = await axios.get(
        `${
          process.env.REACT_APP_API
        }/api/specialists/${checkAccess()}/new_clients/count?from=${from}&to=${to}`,
        { withCredentials: true },
      );
      console.log(data);
      return data;
    },

    getClientsAppointmentCount: async (from, to) => {
      let { data } = await axios.get(
        `${
          process.env.REACT_APP_API
        }/api/specialists/${checkAccess()}/clients_appointments/count?from=${from}&to=${to}`,
        { withCredentials: true },
      );
      return data;
    },

    //Групові

    getSpecialistGroupAppointmentsCount: async (from, to) => {
      let { data } = await axios.get(
        `${
          process.env.REACT_APP_API
        }/api/specialists/${checkAccess()}/group_appointments/count?from=${from}&to=${to}`,
        { withCredentials: true },
      );
      return data;
    },

    getGroupServiceAppointmentsCount: async (from, to) => {
      let { data } = await axios.get(
        `${
          process.env.REACT_APP_API
        }/api/specialists/${checkAccess()}/group_service_appointments/count?from=${from}&to=${to}`,
        { withCredentials: true },
      );
      return data;
    },

    getClientsGroupAppointmentCount: async (from, to) => {
      let { data } = await axios.get(
        `${
          process.env.REACT_APP_API
        }/api/specialists/${checkAccess()}/clients_group_appointments/count?from=${from}&to=${to}`,
        { withCredentials: true },
      );
      return data;
    },
  },
};
