import React, { useState } from 'react';
import {
  AddAppointmentMobile,
  AppointmentDetailsMobile,
  AppointmentsBlockMobile,
  EditAppointmentMobile,
  GroupAppointmentMobile,
} from 'Mobile/MobileCalendar/Components/AppointmentsBlock/AppointmentsBlock';
import { MobileHeader } from '../../Components/Header/Header';
import styles from './MobileCalendar.module.css';
import BottomMenu from '../../Components/BottomMenu/BottomMenu';
import { useEffect } from 'react';
import { useMobileCalendarStore } from './MobileCalendarStore';
import { Scheduler } from 'Pages/Calendar/Components/Scheduler/Scheduler';
import { ServiceInfo } from 'Pages/Booking/Components/AppointmentConfirmation/AppointmentConfirmation';
import {
  AppointmentBlock,
  CancelAppointment,
} from 'Pages/Calendar/Components/AppointmentsBlock/AppointmentsBlock';
import AddContactForm from 'Pages/Clients/Components/AddContactForm/AddContactForm';
import { useDB } from 'utils/DB';
import { StatsPeriodSelector } from 'Pages/Stats/Components/StatsPeriodSelector/StatsPeriodSelector';
import StatsBlock from 'Pages/Stats/Components/StatsBlock/StatsBlock';
import { Badge } from 'rsuite';
import { useTranslation } from 'react-i18next';
import {PaymentService} from '../../Pages/Calendar/Components/AppointmentsBlock/AppointmentsBlock';
import {withTariffGuard, TARIFFS} from '../../utils/tariff.utils';

function MobileCalendar(props) {
  const { t } = useTranslation();
  let { page, setPage } = useMobileCalendarStore();
  let { updateDB } = useDB();

  useEffect(() => {
    let [part1, p] = window.location.href.split('?');

    window.scroll(0, 0);
    p == 'adda' ? setPage('addAppointment') : <></>;

    async function af() {
      await updateDB('specialist');
    }
    af();
  }, []);

  const switcher = (
    <div className={styles.switcher}>
      <div
        onClick={() => {
          setPage('appointments');
        }}
        style={{ color: page == 'appointments' ? 'coral' : 'grey' }}
      >
        {t('bookings')}
      </div>

      <div
        onClick={() => {
          setPage('calendar');
        }}
        style={{ color: page == 'calendar' ? 'coral' : 'grey' }}
      >
        {t('calendar')}
      </div>

      <div
        onClick={ withTariffGuard([TARIFFS.PREMIUM],() => setPage('stats')) }
        style={{ color: page == 'stats' ? 'coral' : 'grey' }}
      >
        <Badge content="Pro" color="orange">
          {t('statistics')}
        </Badge>
      </div>
    </div>
  );

  if (page == 'appointments') {
    return (
      <>
        <MobileHeader />
        <div className={styles.container}>
          {switcher}

          <AppointmentsBlockMobile height={'520px'} />
          <BottomMenu />
        </div>
      </>
    );
  }

  if (page == 'calendar') {
    return (
      <>
        <MobileHeader />
        <div className={styles.container}>
          {switcher}

          <div className={styles.sheduler_container}>
            <Scheduler />
          </div>
        </div>
        <BottomMenu />
      </>
    );
  }

  if (page == 'appointmentDetails') {
    return (
      <>
        <MobileHeader />

        <AppointmentDetailsMobile />
        <PaymentService />
        <CancelAppointment />
      </>
    );
  }

  if (page == 'addAppointment') {
    return (
      <>
        <MobileHeader />
        <AddAppointmentMobile />
      </>
    );
  }

  if (page == 'editAppointment') {
    return (
      <>
        <MobileHeader />
        <EditAppointmentMobile />
      </>
    );
  }

  if (page == 'groupAppointment') {
    return (
      <>
        <MobileHeader />
        <GroupAppointmentMobile />
      </>
    );
  }

  if (page == 'stats') {
    return (
      <>
        <MobileHeader />
        <div className={styles.container}>
          {switcher}
          <StatsPeriodSelector />
          <StatsBlock />
        </div>
        <BottomMenu />
      </>
    );
  }
}
export default MobileCalendar;
