import { create } from 'zustand';
import axios from 'axios';
import moment from 'moment';
import { useChooseServiceStore } from '../ChooseService/ChooseServiceStore';
import { useDatePickerBlockStore } from '../DatePickerBlock/DatePickerBlockStore';
import { useTimePickerBlockStore } from '../TimePickerBlock/TimePickerBlockStore';
import { useBookingStore } from '../../BookingStore';
import { useMobileBookingStore } from '../../../../Mobile/MobileBooking/MobileBookingStore';
import { useToast2 } from '../../../../Components/Toast2/ToastStore';
import { API } from 'utils/api';
import { toastUtility } from 'utils/toast.utility';
import i18n from 'i18n';
let { showToast } = useToast2.getState();

export let useAppointmentConfirmationStore = create((set, get) => ({
  appointment: { name: '', phone: '+380', comment: '' },
  appointmentConfirmationWindow: false,
  appointmentConfirmationSuccessWindow: false,

  showAppointmentConfirmationWindow: () => {
    let { service } = useChooseServiceStore.getState();
    let { date } = useDatePickerBlockStore.getState();
    let { timeSlotStartTime, groupTimeSlot } =
      useTimePickerBlockStore.getState();

    if (!service) {
      showToast('e', 'Оберіть послугу');
      return;
    }

    if (!date) {
      showToast('e', 'Оберіть дату візиту');
      return;
    }

    //Якщо вибрані індивідуальні послуги
    const hasFavoriteSchedule = service.some((s) => s.schedule)
    if (!hasFavoriteSchedule) {
      if (!timeSlotStartTime) {
        showToast('e', 'Оберіть час візиту');
        return;
      }
    }
    //Якщо вибрана послуга групова
    if (hasFavoriteSchedule) {
      if (!groupTimeSlot) {
        showToast('e', 'Оберіть час візиту');
        return;
      }
    }

    if (moment(date).add(1, 'days').format('x') < moment().format('x')) {
      showToast('e', 'Обрана дата вже пройшла');
      return;
    }
    if (
      moment(`${date} ${timeSlotStartTime}`).format('x') < moment().format('x')
    ) {
      showToast('e', 'Обраний час вже пройшов');
      return;
    }

    set({ appointmentConfirmationWindow: true });
  },

  changeAppointmentConfirmationWindowState: (state) => {
    set({ appointmentConfirmationWindow: state });
  },
  changeAppointmentConfirmationSuccesWindowState: (state) => {
    set({ appointmentConfirmationSuccessWindow: state });
  },

  inputHandler: (e, type = '') => {
    if (type === 'phone') {
      let appointment = get().appointment;
      set({ appointment: { ...appointment, phone: e } });

      return;
    }

    set({
      appointment: { ...get().appointment, [e.target.id]: e.target.value },
    });
  },

  confirmAppointment: async () => {
    let { service } = useChooseServiceStore.getState();
    let { date } = useDatePickerBlockStore.getState();
    let { timeSlotStartTime } = useTimePickerBlockStore.getState();

    if (get().appointment.name == '') {
      showToast('e', 'Заповніть поле "Імя" ');
      window.scroll(0, 0);
      return;
    }
    if (get().appointment.phone == '' || get().appointment.phone == '+380') {
      showToast('e', 'Вкажіть Ваш номер телефону');
      window.scroll(0, 0);
      return;
    }

    const { specialistId } = useBookingStore.getState();
    const hasFavoriteSchedule = service.some((s) => s.schedule)
    const serviceIds = service.map((s) => s._id)

    //Якщо вибрана послуга індивідуальна
    if (!hasFavoriteSchedule) {
      let { data } = await axios.post(
        `${process.env.REACT_APP_API}/api/appointments`,
        {
          clientName: get().appointment.name,
          phone: get().appointment.phone,
          comment: get().appointment.comment,
          serviceIds,
          specialistId,
          date: date,
          time: timeSlotStartTime,
        },
      );
      if (data == 'Слот зайнятий') {
        showToast('e', 'Цей час вже зайнятий ');
        window.scroll(0, 0);
      }

      if (data == 1) {
        set({
          appointmentConfirmationWindow: false,
          appointmentConfirmationSuccessWindow: true,
        });

        //For mobile version
        let { setPage } = useMobileBookingStore.getState();
        setPage('success');
      }
    }
    //Якщо вибрана послуга групова
    if (hasFavoriteSchedule) {
      let groupTimeSlot = useTimePickerBlockStore.getState().groupTimeSlot;
      let data = await API.GroupAppointment.addMember({
        from: 'bookingPage',
        clientName: get().appointment.name,
        clientPhone: get().appointment.phone,
        groupAppointmentId: groupTimeSlot.groupAppointmentId,
        specialistId,
      });
      if (data) {
        if (data.isUpdate) {
          toastUtility.showError(i18n.t('already_booked'));
          return;
        }

        set({
          appointmentConfirmationWindow: false,
          appointmentConfirmationSuccessWindow: true,
        });

        //For mobile version
        let { setPage } = useMobileBookingStore.getState();
        setPage('success');
      }
    }
  },
}));
