import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import stylesMobile from './TransactionsM.module.css';
import stylesDesktop from './TransactionsD.module.css';
import { useTransactions } from 'store';

function Transactions({ type }) {
  const { t } = useTranslation();
  const { transactions, getTransactions } = useTransactions();

  useEffect(() => {
    getTransactions();
  }, []);

  const isMobile = type === 'mobile';
  const containerStyle = isMobile ? stylesMobile.transactions_container : stylesDesktop.transactions_container;
  const titleStyle = isMobile ? stylesMobile.transactions_title : stylesDesktop.transactions_title;
  const dataStyle = isMobile ? stylesMobile.transactions_data : stylesDesktop.transactions_data;
  
  return (
    <div className={containerStyle}>
      <div className={titleStyle}>{t('payment_history')}</div>
      <div className={dataStyle}>
        <div>{t('date')}</div>
        <div>{t('type')}</div>
        <div>{t('amount')}</div>
      </div>
      {transactions.map((transaction) => (
        <TransactionRow
          key={transaction._id} 
          date={moment(transaction.created).format('DD.MM.YYYY HH:mm')}
          type={transaction.nameOfProduct}
          sum={transaction.sum / 100}
        />
      ))}
    </div>
  );
}

function TransactionRow({ date, type, sum }) {
  const { i18n } = useTranslation();
  const currency = i18n.language === 'uk' ? 'грн' : 'UAH';

  return (
    <div className={stylesDesktop.transactions_row}>
      <div>{date}</div>
      <div>{type}</div>
      <div>
        {sum} {currency}
      </div>
    </div>
  );
}

export default Transactions;
