import React, { useEffect, useState } from 'react';
import stylesM from './AppointmentsBlockM.module.css';
import { useAppointmentsStore } from 'Pages/Calendar/Components/AppointmentsBlock/AppointmentsStore';
import {
  Button,
  Input,
  TextArea,
  Selector,
} from 'Components/BasicComponents/BasicComponents';
import { useMobileCalendarStore } from 'Mobile/MobileCalendar/MobileCalendarStore';
import moment from 'moment';
import nodata from 'Imgs/nodata.png';
import { AppointmentBlock } from 'Pages/Calendar/Components/AppointmentsBlock/AppointmentsBlock';
import { ServiceInfo } from 'Pages/Booking/Components/AppointmentConfirmation/AppointmentConfirmation';
import styles from 'Pages/Calendar/Components/AppointmentsBlock/AppointmentsBlock.module.css';
import Imgs from 'Imgs/Imgs';
import AddGroupMembers from 'Pages/Calendar/Components/AddGroupMembers/AddGroupMembers';
import { Client } from 'Pages/Services/Components/ServiceEditBlock/ServiceEditBlock';
import AddContactForm from 'Pages/Clients/Components/AddContactForm/AddContactForm';
import SelectClient from 'Pages/Calendar/Components/SelectClient/SelectClient';
import { useTranslation } from 'react-i18next';

//! Mobile

export function AppointmentsBlockMobile(props) {
  const { t } = useTranslation();
  let {
    appointments,
    getSpecialistAppointments,
    showAppointmentDetails,
    showAddAppointmentWindow,
    showGroupAppointmentWindow,
  } = useAppointmentsStore();

  useEffect(() => {
    async function af() {
      await getSpecialistAppointments();
    }
    af();
  }, []);

  let zaglushka = (
    <div className={stylesM.img_container}>
      <img src={nodata} width={'220px'} alt="" />
      <div className={stylesM.nodata_title}>{t('no_records')}</div>
    </div>
  );

  document.body.style.backgroundColor = '#F4F7FE';

  return (
    <>
      <div
        style={{ width: '100%', height: props.height, overflow: 'hidden' }}
        className={stylesM.container}
      >
        <div className={stylesM.header}>
          <div className={stylesM.title}>{t('current_records')}</div>
          <div
            onClick={() => {
              showAddAppointmentWindow();
            }}
            className={stylesM.add_button}
          >
            +
          </div>
        </div>
        <div className={stylesM.line}></div>

        <div className={stylesM.appointments_container}>
          {appointments.length == 0 ? zaglushka : <></>}

          {appointments.map((el) => {
            return [
              <div>
                {el.date == moment().format('YYYY/MM/DD')
                  ? t('today')
                  : moment(el.date).format('DD.MM.YYYY')}
              </div>,
              el.records.map((el) => {
                //Якщо груповий запис
                if (el.groupServiceId) {
                  return (
                    <AppointmentBlock
                      onClick={() => {
                        showGroupAppointmentWindow(el._id);
                      }}
                      serviceName={`${t('group')}: ${el.groupServiceName} `}
                      timeRange={`${el.from}-${el.to} `}
                      members={`${el.participants.length}/${el.maxNumberOfParticipants}`}
                    />
                  );
                }
                //Якщо звичайний запис

                return (
                  <AppointmentBlock
                    onClick={() => {
                      showAppointmentDetails(el._id);
                    }}
                    serviceName={el.clientName}
                    timeRange={el.timeRange}
                  />
                );
              }),
            ];
          })}

          <div style={{ height: '80px' }}></div>
        </div>
      </div>
    </>
  );
}

export function AppointmentDetailsMobile(props) {
  const { t } = useTranslation()
  let { appointment, showConfirmDeleteWindow, hideAppointmentDetailsWindow, showPaymentServiceWindow } =
    useAppointmentsStore();

  let { setPage } = useMobileCalendarStore();

  return (
    <>
      <div className={stylesM.mobile_container}>
        <div className={stylesM.title_container}>
          <div className={stylesM.popup_title}>{t('service_booking')}</div>
          <Imgs
            img={'edit'}
            width={'22px'}
            onClick_Edit={() => {
              setPage('editAppointment');
            }}
          />
        </div>
        <div className={stylesM.inner_details_container}>
          <div>{t('service')}</div>
          <div className={stylesM.serviceName}>{appointment?.serviceName}</div>

          <div>{t('name')}</div>
          <div className={stylesM.fullname}>{appointment?.clientName}</div>
          <div>{t('phone')}:</div>
          <div className={stylesM.phone}>{appointment?.phone}</div>
          <div className={stylesM.comment}>{appointment?.comment}</div>

          <div className={stylesM.service_info}>
            <ServiceInfo
              t={'m'}
              type={'time'}
              value={`${moment(appointment?.date).format('DD.MM.YYYY')} / ${
                appointment?.time
              }`}
            />
            <ServiceInfo t={'m'} type={'price'} value={appointment?.price} />
          </div>

          <div className={stylesM.buttons_container}>
           <Button
              name={t('payment')}
              onClick={() => {
                showPaymentServiceWindow();
              }}
              style={{marginBottom: 10}}
            />
            <Button
              color={'black'}
              name={t('cancel_booking')}
              onClick={showConfirmDeleteWindow}
            />
            <Button
              onClick={() => {
                setPage('appointments');
                hideAppointmentDetailsWindow();
              }}
              name={t('back')}
              style={{
                backgroundColor: '#ffff',
                color: 'grey',
                height: '50px',
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export function AddAppointmentMobile(props) {
  const { t, i18n } = useTranslation();

  moment.locale(i18n.language);
  
  let {
    appointment,
    getSpecialistData,
    getSpecialistServices,
    inputHandler,
    specialistServices,
    addAppointment,
  } = useAppointmentsStore();

  let { setPage } = useMobileCalendarStore();

  useEffect(() => {
    async function af() {
      await getSpecialistData();
      await getSpecialistServices();
    }
    af();
  }, []);

  document.body.style.backgroundColor = 'white';

  return (
    <>
      <AddContactForm />
      <div className={styles.mobile_container}>
        <div className={styles.popup_title}>{t('create_record')}</div>

        <div className={styles.inner_container}>
          <div className={styles.service}>
            <div className={styles.input_title}>{t('service')}</div>
            <Selector
              id={'serviceId'}
              value={appointment?.serviceId}
              onChange={inputHandler}
              options={[]}
              type={'custom'}
              customOptions={specialistServices.map((el) => {
                return <option value={el._id}>{el.name}</option>;
              })}
            />
          </div>

          <div className={styles.service_info}>
            <div style={{ width: '250px' }}>
              <div>{t('date')}</div>
              <input
                value={moment(appointment?.date).format('YYYY-MM-DD')}
                style={{ width: '175px' }}
                id={'date'}
                onChange={inputHandler}
                type={'date'}
                className={styles.input}
              ></input>
            </div>
            <div style={{ width: '170px' }}>
              {t('time')}
              <Selector
                id={'time'}
                value={appointment?.time}
                onChange={inputHandler}
                options={times}
              />
            </div>
          </div>

          <SelectClient source={'addAppointment'} />

          {/* <Input
              id={"clientName"}
              value={appointment?.clientName}
              onChange={inputHandler}
              placeholder={"Імя"}
            />
            <Input
              id={"phone"}
              value={appointment?.phone}
              onChange={inputHandler}
              placeholder={"Телефон"}
            /> */}
          <TextArea
            value={appointment?.comment}
            onFocus={() => {
              window.ReactNativeWebView.postMessage('textInputOnFocus');
            }}
            id={'comment'}
            onChange={inputHandler}
            style={{ height: '90px' }}
            placeholder={t('comment')}
          />

          <Button
            name={t('confirm')}
            color={'black'}
            onClick={addAppointment}
          />
          <Button
            onClick={() => {
              setPage('appointments');
            }}
            name={t('back')}
            style={{ backgroundColor: '#ffff', color: 'grey', height: '50px' }}
          />
        </div>
      </div>
    </>
  );
}

export function EditAppointmentMobile(props) {
  const { t, i18n } = useTranslation();

  moment.locale(i18n.language);

  let {
    appointment,
    getSpecialistData,
    getSpecialistServices,
    inputHandler,
    specialistServices,
    updateAppointment,
  } = useAppointmentsStore();

  let { setPage } = useMobileCalendarStore();

  useEffect(() => {
    async function af() {
      await getSpecialistData();
      await getSpecialistServices();
    }
    af();
  }, []);

  document.body.style.backgroundColor = 'white';

  return (
    <>
      <AddContactForm />

      <div className={styles.mobile_container}>
        <div className={styles.popup_title}>{t('edit_booking')}</div>

        <div className={styles.inner_container}>
          <div className={styles.service}>
            <div className={styles.input_title}>{t('service')}</div>
            <Selector
              id={'serviceId'}
              value={appointment?.serviceId}
              onChange={inputHandler}
              options={[]}
              type={'custom'}
              customOptions={specialistServices.map((el) => {
                return <option value={el._id}>{el.name}</option>;
              })}
            />
          </div>

          <div className={styles.service_info}>
            <div style={{ width: '250px' }}>
              <div>{t('date')}</div>
              <input
                value={moment(appointment?.date).format('YYYY-MM-DD')}
                style={{ width: '175px' }}
                id={'date'}
                onChange={inputHandler}
                type={'date'}
                className={styles.input}
              ></input>
            </div>
            <div style={{ width: '170px' }}>
              {t('time')}
              <Selector
                id={'time'}
                value={appointment?.time}
                onChange={inputHandler}
                options={times}
              />
            </div>
          </div>

          <SelectClient
            source={'editAppointment'}
            clientPhone={appointment?.phone}
          />

          {/* <Input
              id={"clientName"}
              value={appointment?.clientName}
              onChange={inputHandler}
              placeholder={"Імя"}
            />
            <Input
              id={"phone"}
              value={appointment?.phone}
              onChange={inputHandler}
              placeholder={"Телефон"}
            /> */}
          <TextArea
            value={appointment?.comment}
            onFocus={() => {
              window.ReactNativeWebView.postMessage('textInputOnFocus');
            }}
            id={'comment'}
            onChange={inputHandler}
            style={{ height: '90px' }}
            placeholder={t('comment')}
          />

          <Button
            name={t('save_changes')}
            color={'black'}
            onClick={updateAppointment}
          />
          <Button
            onClick={() => {
              setPage('appointments');
            }}
            name={t('back')}
            style={{ backgroundColor: '#ffff', color: 'grey', height: '50px' }}
          />
        </div>
      </div>
    </>
  );
}

export function GroupAppointmentMobile() {
  const { t } = useTranslation();
  let { setPage } = useMobileCalendarStore();

  let { groupAppointment, groupMembers, deleteGroupMember, saveGroupMembers, deleteGroup } =
    useAppointmentsStore();

    const [isSendSMS, setSendSMS] = useState(false)
    const [appointmentModal, setAppointmentModal] =  useState({
      isOpen: false,
      groupAppointmentId: null
    })
  
    const onGroupDelete = async (groupAppointmentId, isSendSMS) => {
      deleteGroup(groupAppointmentId, isSendSMS)
      onGroupClose()
    }
    
    const onGroupClose = () => {
      setAppointmentModal({isOpen: false, groupAppointmentId: null})
      setSendSMS(false)
      setPage('appointments');
    }

  return (
    <>
      <AddContactForm />
      <div className={stylesM.mobile_container}>
        <div className={stylesM.title_container}>
          <div className={stylesM.popup_title}>
            {groupAppointment?.groupServiceName}
          </div>
          <div className={styles.group_appointment_time}>
            {moment(groupAppointment.date).format('dddd')}{' '}
            {groupAppointment?.from}
          </div>
        </div>
        <div className={stylesM.group_appointment_group}>
          <AddGroupMembers />

          <button type='button' 
            onClick={() => setAppointmentModal({isOpen: true, groupAppointmentId: groupAppointment?._id})}
            className={stylesM.group_appointment_group__delete}>
            <Imgs img={'delete'} />
          </button>
        </div>

        <div className={styles.group_members}>
          {groupMembers.map((el) => (
            <Client
              onClick_Delete={() => {
                deleteGroupMember(el._id);
              }}
              initials={el.name[0]}
              name={el.name}
              surname={el.surname}
              phone={el.phone}
            />
          ))}
        </div>

        <div className={stylesM.buttons_container}>
          <Button
            color={'black'}
            name={t('save_changes')}
            onClick={saveGroupMembers}
          />
          <Button
            onClick={() => {
              setPage('appointments');
            }}
            name={t('back')}
            style={{
              backgroundColor: 'transparent',
              color: 'grey',
              height: '50px',
            }}
          />
        </div>

        {
          appointmentModal.isOpen && (
            <div className={styles.popup_background}>
              <div style={{ width: '350px' }} className={styles.popup_window}>
                <div
                  className={styles.close_container}
                  onClick={() => onGroupClose()}
                >
                  <Imgs img={'close'} />
                </div>
                <div className={styles.text}>
                  {t('delete_training')}
                </div>
                <div className={styles.modal_buttons}>
                  <Button
                    onClick={() => setSendSMS(true)}
                    name={t('yes')}
                    color={'black'}
                    style={{ marginTop: 20, height: '40px' }}
                  />
                  <Button
                    onClick={onGroupClose}
                    name={t('no')}
                    color={'black'}
                    style={{ marginTop: 20, height: '40px' }}
                  />
                </div>
              </div>
            </div>
          )
        }
        {
          isSendSMS && (
            <div className={styles.popup_background}>
              <div style={{ width: '350px' }} className={styles.popup_window}>
                <div
                  className={styles.close_container}
                  onClick={() => onGroupClose()}
                >
                  <Imgs img={'close'} />
                </div>
                <div className={styles.text}>
                  {t('notify_participants_sms')}
                </div>
                <div className={styles.modal_buttons}>
                  <Button
                    onClick={() => onGroupDelete(appointmentModal.groupAppointmentId, true)}
                    name={t('yes')}
                    color={'black'}
                    style={{ marginTop: 20, height: '40px' }}
                  />
                  <Button
                    onClick={() => onGroupDelete(appointmentModal.groupAppointmentId, false)}
                    name={t('no')}
                    color={'black'}
                    style={{ marginTop: 20, height: '40px' }}
                  />
                </div>
              </div>
            </div>
          )
        }
      </div>
    </>
  );
}

//Допоміжні

//Час для запису
// let times = [
//   "08:00",
//   "08:15",
//   "08:30",
//   "08:45",
//   "09:00",
//   "09:15",
//   "09:30",
//   "09:45",
//   "10:00",
//   "10:15",
//   "10:30",
//   "10:45",
//   "11:00",
//   "11:15",
//   "11:30",
//   "11:45",
//   "12:00",
//   "12:15",
//   "12:30",
//   "12:45",
//   "13:00",
//   "13:15",
//   "13:30",
//   "13:45",
//   "14:00",
//   "14:15",
//   "14:30",
//   "14:45",
//   "15:00",
//   "15:15",
//   "15:30",
//   "15:45",
//   "16:00",
//   "16:15",
//   "16:30",
//   "16:45",
//   "17:00",
//   "17:15",
//   "17:30",
//   "17:45",
//   "18:00",
//   "18:15",
//   "18:30",
//   "18:45",
//   "19:00",
//   "19:15",
//   "19:30",
//   "19:45",
//   "20:00",
//   "20:15",
//   "20:30",
//   "20:45",
//   "21:00",
//   "21:15",
//   "21:30",
//   "21:45",
//   "22:00",
//   "22:15",
//   "22:30",
//   "22:45",
// ];

let times = [
  '00:00',
  '00:15',
  '00:30',
  '00:45',
  '01:00',
  '01:15',
  '01:30',
  '01:45',
  '02:00',
  '02:15',
  '02:30',
  '02:45',
  '03:00',
  '03:15',
  '03:30',
  '03:45',
  '04:00',
  '04:15',
  '04:30',
  '04:45',
  '05:00',
  '05:15',
  '05:30',
  '05:45',
  '06:00',
  '06:15',
  '06:30',
  '06:45',
  '07:00',
  '07:15',
  '07:30',
  '07:45',
  '08:00',
  '08:15',
  '08:30',
  '08:45',
  '09:00',
  '09:15',
  '09:30',
  '09:45',
  '10:00',
  '10:15',
  '10:30',
  '10:45',
  '11:00',
  '11:15',
  '11:30',
  '11:45',
  '12:00',
  '12:15',
  '12:30',
  '12:45',
  '13:00',
  '13:15',
  '13:30',
  '13:45',
  '14:00',
  '14:15',
  '14:30',
  '14:45',
  '15:00',
  '15:15',
  '15:30',
  '15:45',
  '16:00',
  '16:15',
  '16:30',
  '16:45',
  '17:00',
  '17:15',
  '17:30',
  '17:45',
  '18:00',
  '18:15',
  '18:30',
  '18:45',
  '19:00',
  '19:15',
  '19:30',
  '19:45',
  '20:00',
  '20:15',
  '20:30',
  '20:45',
  '21:00',
  '21:15',
  '21:30',
  '21:45',
  '22:00',
  '22:15',
  '22:30',
  '22:45',
  '23:00',
  '23:15',
  '23:30',
  '23:45',
  '24:00',
];
