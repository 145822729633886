import { create } from 'zustand';
import i18n from 'i18n';
import axios from 'axios';
import { checkAccess } from '../../../../access';
import { useToast2 } from '../../../../Components/Toast2/ToastStore';
import { API } from 'utils/api';
import { useCasaStore } from '../../CasaStore';
import moment from 'moment';

const { showToast } = useToast2.getState();


export let useTransactionModalStore = create((set, get) => ({
  isOpen: false,
  isPreview: false,
  clientsOptions: [],
  servicesOptions: [],
  formData: {
    date: moment().format("YYYY-MM-DD"),
    time: '',
    amount: 0,
    currency: '',
    transactionType: 'income',
    paymentMethod: null,
    categoryName: null,
    clientName: null,
    servicesName: null,
    description: '',
  },

  clearForm: () => {
    set({
      formData: {
        date: moment().format("YYYY-MM-DD"),
        time: '',
        amount: 0,
        transactionType: 'income',
        clientName: null,
        servicesName: null,
        description: '',
      },
    });
  },

  inputHandler: (e, options) => {
    const id = e?.target?.id
    const value = e?.target?.value

    if (options) {
      const label = options.find(({ value: v }) => v == value)?.label
      set({ formData: { ...get().formData, [id]: { value, label } } });
      return
    }

    set({ formData: { ...get().formData, [id]: value } });
  },

  handleToggleWindow: (state) => {
    if (state === false) {
      get().clearForm()
      set({ isPreview: false });
    }

    set({ isOpen: state });
  },

  handleTransactionForm: (formData) => {
    set({ formData: { ...get().formData, ...formData } })
  },
  saveTransaction: async () => {
    try {
      const { formData } = get();

      if (!formData.amount) {
        showToast('e', `${i18n.t('fin_amount')}* !`);
        return;
      }
      if (!formData.clientName) {
        showToast('e', `${i18n.t('fin_client')}* !`);
        return;
      }
      if (!formData.servicesName) {
        showToast('e', `${i18n.t('fin_service')}* !`);
        return;
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API}/api/create-transaction/${checkAccess()}`,
        formData
      );

      if (data) {
        const transactions = useCasaStore.getState().transactionsAll;
        const newTransactions = [...transactions, data.transaction];

        useCasaStore.getState().recalculateTransactions(newTransactions);
        get().clearForm();

        set({ isOpen: false });
        showToast('s', i18n.t('transaction_successfully_created'));
      }

    } catch (error) {
      showToast('e', i18n.t('Error!'));
    }
  },
  updateTransaction: async () => {
    try {
      const transactionId = get().formData._id
      const { data } = await axios.post(
        `${process.env.REACT_APP_API}/api/update-transaction/${transactionId}`,
        {
          ...get().formData,
        }
      );

      if (data) {
        const transactions = useCasaStore.getState().transactionsAll.filter(({ _id }) => _id !== transactionId)
        const updateTransactions = [...transactions, data.transaction]

        useCasaStore.getState().recalculateTransactions(updateTransactions)

        get().clearForm();
        set({ isOpen: false, isPreview: false });
        showToast('s', i18n.t('transaction_successfully_created'));
      }

    } catch (error) {
      showToast('e', i18n.t('Error!'));
    }
  },

  cancelTransaction: async () => {
    try {
      const transactionId = get().formData?._id

      const { data } = await axios.delete(
        `${process.env.REACT_APP_API}/api/transaction/${checkAccess()}/${transactionId}`,
      );

      if (data.success) {
        const transactions = useCasaStore.getState().transactionsAll
        const deleteTransactions = transactions.filter(({ _id }) => _id !== transactionId)

        useCasaStore.getState().recalculateTransactions(deleteTransactions)

        get().clearForm();
        set({ isOpen: false, isPreview: false });
        showToast('s', i18n.t('fin_transaction_deleted__message'));
      }

    } catch (error) {
      showToast('e', i18n.t('Error!'));
    }
  },


  editTransaction: () => {
    set({ isPreview: true })
  },

  addCashPayment: (formData) => {
    set({ isOpen: true })
    set({ formData: { ...get().formData, ...formData } })
  },

  getSpecialistsClients: async () => {
    const data = await API.Client.getAll();
    const options = data.map((client) => {
      return {
        value: client._id,
        label: `${client.name} ${client.surname}`,
      };
    });

    const clientsOptions = [
      {
        value: null,
        label: '',
      },
      ...options,
    ]
    set({ clientsOptions });
  },

  getSpecialistServices: async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/api/specialist_services/${checkAccess()}`,
      { withCredentials: true },
    );

    const options = data.map((services) => {
      return {
        value: services._id,
        label: services.name,
      };
    });

    const servicesOptions = [
      {
        value: null,
        label: '',
      },
      ...options,
    ]

    set({ servicesOptions });
  },
}));

