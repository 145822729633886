import React from 'react';
import styles from './CancelAppointmentModal.module.css';
import Imgs from 'Imgs/Imgs';

import { useTranslation } from 'react-i18next';
import {Button} from 'Components/BasicComponents/BasicComponents';
import { useCancelAppointmentStore } from '../CancelAppointment/CancelAppointmentStore';
import planguin from '../../../../Imgs/planguin.png';

export function CancelAppointmentModal(props) {
  const { t } = useTranslation();
    
  let {
    cancelAppointment,
    confirmDeleteWindow,
    hideConfirmDeleteWindow,
    confirmDeleteSuccessWindow,
    changeConfirmDeleteSuccessWindowState,
    cancelServiceWindowState,
  } = useCancelAppointmentStore();

  if (confirmDeleteWindow) {
    return (
      <>
        <div className={styles.popup_background}>
          <div style={{ width: '350px' }} className={styles.popup_window}>
            <div
              className={styles.close_container}
              onClick={hideConfirmDeleteWindow}
            >
              <Imgs img={'close'} />
            </div>
            <div className={styles.text}>
              {t('cancel_visit_confirmation')}
            </div>
            <Button
              onClick={cancelAppointment}
              name={t('confirm')}
              color={'black'}
              style={{ marginTop: '30px' }}
            />
          </div>
        </div>
      </>
    );
  }

  if (confirmDeleteSuccessWindow) {
    return (
      <>
        <div className={styles.popup_background}>
          <div style={{ width: '350px' }} className={styles.popup_window}>
            <div className={styles.close_container}>
              <span
                onClick={() => {
                  changeConfirmDeleteSuccessWindowState(false);
                }}
                className={styles.close}
              >
                <Imgs img={'close'} />
              </span>
            </div>

            <div className={styles.img_block}>
              <div className={styles.img_title}>{t('delete_appointment_success')} ✅</div>
              <div className={styles.img}>
                <img src={planguin} alt="" />
              </div>
            </div>
            
            <Button
              name={t('add_new_appointment_success')}
              color={'black'}
              onClick={() => {
                changeConfirmDeleteSuccessWindowState(false);
                cancelServiceWindowState(false);
              }}
            />
          </div>
        </div>
      </>
    );
  }

  return null
}
