import { create } from 'zustand';
import axios from 'axios';
import moment from 'moment';
import { API } from 'utils/api';
import { useBookingStore } from 'Pages/Booking/BookingStore';
import { useChooseServiceStore } from '../ChooseService/ChooseServiceStore';

export let useDatePickerBlockStore = create((set, get) => ({
  date: moment().format('YYYY/MM/DD'),
  groupAppointments: [],

  selectDate: (date) => {
    set({ date });
  },

  getDayType: (el, date) => {
    if (moment().add(-1, 'days').format('x') > moment(el).format('x')) {
      if (date == el) {
        set({ date: moment().add(+1, 'days').format('YYYY/MM/DD')})
      }

      return 'past';
    }

    if (date == el) {
      return 'selected';
    }

    let service = useChooseServiceStore.getState().service;
    if (!service) {
      return;
    }

    if (service?.[0]?.schedule) {
      // Якщо сервіс груповий

      let allGroupTimeSlots =
        useChooseServiceStore.getState().allGroupTimeSlots;
      let datesOfGroupAppointments = allGroupTimeSlots.map((item) => item.date);

      if (datesOfGroupAppointments.includes(el)) {
        return 'hasTimeslots';
      } else {
        return 'noTimeslots';
      }
    }
  },
}));
