import { useTranslation } from 'react-i18next';
import ruRU from 'rsuite/locales/ru_RU';
import deDE from 'rsuite/locales/de_DE';
import enUS from 'rsuite/locales/en_US';
import defaultLng from 'rsuite/locales/default';

export const useTransactionTypeOptions = () => {
  const { t } = useTranslation();

  return [
    { value: 'income', label: t('fin_income_details') },
    { value: 'expense', label: t('fin_expenses_details') },
  ];
}

export const useDateRangePickerLocale = () => {
  const { i18n } = useTranslation();

  switch (i18n.language) {
    case i18n.language === 'uk':
      return defaultLng;

    case i18n.language === 'ru':
      return ruRU;

    case i18n.language === 'en':
      return enUS;

    case i18n.language === 'de':
      return deDE;

    default:
      return defaultLng;
  }
};