import { create } from 'zustand';
import i18n from 'i18n';
import axios from 'axios';
import { checkAccess } from '../../../../access';
import { useToast2 } from '../../../../Components/Toast2/ToastStore';

const { showToast } = useToast2.getState();


export let useCashModalsStore = create((set, get) => ({
  settingsWindow: false,
  cashWindow: false,
  expenseWindow: false,
  incomeWindow: false,
  hasChanged: false,
  settings: {
    paymentMethods: [],
    categoriesIncome: [],
    categoriesExpense: [],
  },

  closeWindows: () => {
    set({
      settingsWindow: false,
      cashWindow: false,
      expenseWindow: false,
      incomeWindow: false
    });
  },

  handleSettingsWindow: (state) => {
    get().closeWindows()
    set({ settingsWindow: state });
  },

  handleCashWindow: (state) => {
    set({ cashWindow: state });
  },

  handleExpenseWindow: (state) => {
    set({ expenseWindow: state });
  },

  handleIncomeWindow: (state) => {
    set({ incomeWindow: state });
  },
  getCategoriesSettings: async (state) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/transaction-settings/${checkAccess()}`
      );

      if (!data?.message) {
        const mapOptionsWithTranslation = (options, translationKey) =>
          options.map((option) =>
            option.value === 1
              ? { value: option.value, label: i18n.t(translationKey) }
              : { ...option }
          );

        const paymentMethods = mapOptionsWithTranslation(
          data.paymentMethods,
          'fin_cash_flow'
        );

        const categoriesIncome = mapOptionsWithTranslation(
          data.paymentMethods,
          'fin_service'
        );

        const settingsData = {
          ...data,
          paymentMethods,
          categoriesIncome,
        };

        set({ settings: settingsData });
      }
    } catch (error) {
      showToast('e', i18n.t('Error categories settings!'));
    }
  },

  saveCategoriesSettings: async (categoryKey) => {
    try {
      const isEmpty = get().settings[categoryKey].filter(({ value }) => value === '').length !== 0

      if (!get().settings[categoryKey].length) {
        showToast('e', i18n.t('fin_add_category'));
        return
      }

      if (isEmpty) {
        showToast('e', i18n.t('field_cannot_be_empty'));
        return
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API}/api/create-transaction-settings/${checkAccess()}`,
        {
          categories: { [categoryKey]: get().settings[categoryKey] }
        }
      );

      if (data) {
        showToast('s', i18n.t('transaction_settings_successfully_created'));
      }
      set({ hasChanged: false });

    } catch (error) {
      showToast('e', i18n.t('Error!'));
    }
  },

  addCategory: (category) => {
    const newCategoryId = get().settings[category].length + 1;
    set({
      settings: {
        ...get().settings,
        [category]: [...get().settings[category], { value: newCategoryId, label: '' }]
      }
    })
  },

  deleteCategory: async (categoryId, category) => {
    const removeCategory = get().settings[category].filter(({ value }) => categoryId !== value);

    const { data } = await axios.post(
      `${process.env.REACT_APP_API}/api/create-transaction-settings/${checkAccess()}`,
      {
        categories: { [category]: removeCategory }
      }
    );

    if (data) {
      set({ settings: { ...get().settings, [category]: removeCategory } })
      showToast('s', i18n.t('deleted'));
    }
  },

  handleCategory: (e, categoryKey) => {
    const id = Number(e?.target?.id)
    const value = e?.target?.value

    const categories = get().settings[categoryKey].map((category) => {
      if (category.value === id) {
        return { value: id, label: value }
      }
      return category
    });

    set({
      hasChanged: true,
      settings: { ...get().settings, [categoryKey]: categories }
    });
  },
}));

