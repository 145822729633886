import { LanguageEnum } from "./enums";
import { useTranslation } from 'react-i18next';
import subDays from 'date-fns/subDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';

export const useCountryOptions = () => {
  const { t } = useTranslation();

  return [
    { value: LanguageEnum.Uk, label: t('countries.Ukraine') },
    { value: LanguageEnum.En, label: t('countries.USA') },
    { value: LanguageEnum.De, label: t('countries.Europe') },
    { value: LanguageEnum.Us, label: t('countries.GreatBritain') },
    { value: LanguageEnum.As, label: t('countries.Asia') },
    { value: LanguageEnum.Ca, label: t('countries.Canada') },
    { value: LanguageEnum.Au, label: t('countries.Australia') },
  ];
}

export const currencyOptions = [
  { value: LanguageEnum.Uk, label: 'Ukrainian Hryvnia - UAH' },
  { value: LanguageEnum.En, label: 'United States Dollar - USD' },
  { value: LanguageEnum.De, label: 'Euro - EUR' },
  { value: LanguageEnum.Ru, label: 'Russian Ruble - RUB' },
  { value: LanguageEnum.Us, label: 'British Pound - GBP' },
  { value: LanguageEnum.Jp, label: 'Japanese Yen - JPY' },
  { value: LanguageEnum.Cn, label: 'Chinese Yuan - CNY' },
];

export const languageOptions = [
  { value: LanguageEnum.Uk, label: "🇺🇦 Українська" },
  { value: LanguageEnum.En, label: "🇬🇧 English" },
  { value: LanguageEnum.De, label: "🇩🇪 Deutsch" },
  { value: LanguageEnum.Ru, label: "🇷🇺 Російська" },
]

export const usePredefinedRanges = () => {
  const { t } = useTranslation();

  return [
    {
      label: t('last_7_days'),
      value: [subDays(new Date(), 6), new Date()],
      placement: 'left',
    },
    {
      label: t('last_30_days'),
      value: [subDays(new Date(), 29), new Date()],
      placement: 'left',
    },
    {
      label: t('current_month'),
      value: [startOfMonth(new Date()), endOfMonth(addMonths(new Date(), 0))],
      placement: 'left',
    },
    {
      label: t('last_month'),
      value: [
        startOfMonth(addMonths(new Date(), -1)),
        endOfMonth(addMonths(new Date(), -1)),
      ],
      placement: 'left',
    },
    {
      label: t('last_year'),
      value: [
        new Date(new Date().getFullYear() - 1, 0, 1),
        new Date(new Date().getFullYear(), 0, 0),
      ],
      placement: 'left',
    },
    {
      label: t('all_time'),
      value: [new Date(new Date().getFullYear() - 2, 0, 1), new Date()],
      placement: 'left',
    },
  ]
};

export const timesOptions = [
  '00:00',
  '00:15',
  '00:30',
  '00:45',
  '01:00',
  '01:15',
  '01:30',
  '01:45',
  '02:00',
  '02:15',
  '02:30',
  '02:45',
  '03:00',
  '03:15',
  '03:30',
  '03:45',
  '04:00',
  '04:15',
  '04:30',
  '04:45',
  '05:00',
  '05:15',
  '05:30',
  '05:45',
  '06:00',
  '06:15',
  '06:30',
  '06:45',
  '07:00',
  '07:15',
  '07:30',
  '07:45',
  '08:00',
  '08:15',
  '08:30',
  '08:45',
  '09:00',
  '09:15',
  '09:30',
  '09:45',
  '10:00',
  '10:15',
  '10:30',
  '10:45',
  '11:00',
  '11:15',
  '11:30',
  '11:45',
  '12:00',
  '12:15',
  '12:30',
  '12:45',
  '13:00',
  '13:15',
  '13:30',
  '13:45',
  '14:00',
  '14:15',
  '14:30',
  '14:45',
  '15:00',
  '15:15',
  '15:30',
  '15:45',
  '16:00',
  '16:15',
  '16:30',
  '16:45',
  '17:00',
  '17:15',
  '17:30',
  '17:45',
  '18:00',
  '18:15',
  '18:30',
  '18:45',
  '19:00',
  '19:15',
  '19:30',
  '19:45',
  '20:00',
  '20:15',
  '20:30',
  '20:45',
  '21:00',
  '21:15',
  '21:30',
  '21:45',
  '22:00',
  '22:15',
  '22:30',
  '22:45',
  '23:00',
  '23:15',
  '23:30',
  '23:45',
  '24:00',
];