import React, { useEffect } from 'react';
import styles from './AddGroupMembers.module.css';
import Select from 'react-select';
import { useAddGroupMembersStore } from './AddGroupMembersStore';
import { useTranslation } from 'react-i18next';

function AddGroupMembers() {
  const { t } = useTranslation();
  let {
    state,
    changeState,
    getSpecialistClients,
    clientsOptions,
    clientSearchInputValue,
    clientSearchInputHandler,
  } = useAddGroupMembersStore();

  useEffect(() => {
    async function af() {
      await getSpecialistClients();
    }
    af();
  }, []);

  let colorStyles = {
    option: (styles, { data }) => {
      return {
        ...styles,
        color:  data.color,
      };
    },
  };

  let { option } = colorStyles;

  if (state == 'button') {
    return (
      <>
        <div
          style={{ color: '#FF8A35', marginTop: '15px', marginBottom: '15px' }}
          onClick={() => {
            changeState('select');
          }}
        >
          + {t('add_participant')}
        </div>
      </>
    );
  }

  if (state == 'select') {
    return (
      <>
        <div style={{ height: '20px' }}></div>
        <Select
          placeholder={t('select_or_create_client')}
          options={clientsOptions}
          inputValue={undefined}
          value={clientSearchInputValue}
          onChange={clientSearchInputHandler}
          noOptionsMessage={() => t('no_contacts_found')}
          //  styles={colorStyles}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }), option }}
        />
      </>
    );
  }
}

export default AddGroupMembers;
