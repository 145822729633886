import styles from './StatsPeriodSelector.module.css';

import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { CustomProvider } from 'rsuite';
import { useStatsBlock } from '../StatsBlock/useStatsBlock';
import { useTranslation } from 'react-i18next';
import {usePredefinedRanges} from 'utils/vars';
import {useDateRangePickerLocale} from '../../../../utils/hooks';

export function StatsPeriodSelector() {
  const { t } = useTranslation();
  const predefinedRanges = usePredefinedRanges()
  const dateRangePickerLocale = useDateRangePickerLocale()

  let { calendarHandler, period } = useStatsBlock();

  return (
    <>
      <div className={styles.stats_period_selector_container}>
        <h3 className={styles.title} style={{ color: '#282828' }}>
          {t('statistics')}
        </h3>
        <CustomProvider locale={dateRangePickerLocale}>
          <DateRangePicker
            format="dd/MM/yyyy"
            placement="auto"
            showOneCalendar={window.innerWidth < 1024 ? true : false}
            locale={dateRangePickerLocale}
            isoWeek="8601"
            placeholder={t('choose_time_slot')}
            ranges={
              window.innerWidth < 1024
                ? predefinedRanges.map((range) => ({
                    ...range,
                    placement: 'bottom',
                  }))
                : predefinedRanges
            }
            value={period}
            onChange={calendarHandler}
          />
        </CustomProvider>
      </div>
    </>
  );
}
