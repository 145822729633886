import { create } from 'zustand';
import axios from 'axios';
import { useToast2 } from '../../../../Components/Toast2/ToastStore';
import i18n from 'i18n';
import {API} from 'utils/api';
let { showToast } = useToast2.getState();

export let useCancelAppointmentStore = create((set, get) => ({
  appointmentsAll: [],
  appointment: null,
  chooseServiceWindow: false,
  confirmDeleteWindow: false,
  confirmPhoneNumberWindow: false,
  confirmPhone: '',
  confirmDeleteSuccessWindow: false,
  windowSection: 'services',

  handleConfirmPhone: (state) => {
    set({ confirmPhone: state });
  },

  changeConfirmDeleteSuccessWindowState: (state) => {
    set({ confirmDeleteSuccessWindow: state });
  },

  changeWindowSection: (section) => {
    set({ windowSection: section });
  },

  phoneNumberWindowState: (state) => {
    set({ confirmPhoneNumberWindow: state });
  },

  cancelServiceWindowState: (state) => {
    set({ chooseServiceWindow: state, confirmPhoneNumberWindow: false });
  },

  showConfirmDeleteWindow: (appointment) => {
    set({ appointment: appointment, confirmDeleteWindow: true });
  },

  hideConfirmDeleteWindow: () => {
    set({ confirmDeleteWindow: false });
  },


  getSpecialistAppointments: async (specialistId) => {
    //Групові записи

    let { data: groupAppointments } = await axios.get(
      `${
        process.env.REACT_APP_API
      }/api/specialists/${specialistId}/group_appointments`,
      { withCredentials: true },
    );

    // Звичайні записи
    let { data } = await axios.get(
      `${
        process.env.REACT_APP_API
      }/api/specialists/${specialistId}/appointments`,
    );

    // З'єднаємо групові та звичайні записи
    data = [...groupAppointments, ...data];

    set({ appointmentsAll: data });
  },

  cancelAppointment: async () => {
    const { appointment } = get();
    
    const closeWindowsAndShowSuccess = (message) => {
      set({ confirmDeleteWindow: false, appointment: null });
      set({ confirmDeleteSuccessWindow: true });
      showToast('s', message);
    };

    if (appointment.groupServiceId) {
      const { confirmPhone } = get();
      const { participants, _id: groupId } = appointment;

      const filteredParticipants = participants.filter(({ phone }) => phone !== confirmPhone);
      const { name, surname } = participants.find(({ phone }) => phone === confirmPhone) || {};

      const response = await API.GroupAppointment.cancelAppointmentGroup({
        groupId,
        groupMembers: filteredParticipants,
        fullName: `${name || ''} ${surname || ''}`.trim()
      });

      if (response) {
        closeWindowsAndShowSuccess(i18n.t('deleted'));
      } else {
        showToast('e', i18n.t('delete_failed'));
      }
    } else {
      const { data } = await axios.delete(`${process.env.REACT_APP_API}/api/appointments/${appointment._id}/cancel`);

      if (data === 1) {
        closeWindowsAndShowSuccess(i18n.t('appointment_cancelled'));
      }
    }
  }
 
}));
