import { create } from 'zustand';
import axios from 'axios';
import { checkAccess } from '../../../../access';
import moment from 'moment';

import { useMobileCalendarStore } from 'Mobile/MobileCalendar/MobileCalendarStore';

import { useToast2 } from '../../../../Components/Toast2/ToastStore';
import { API } from 'utils/api';
import { useSelectClient } from '../SelectClient/useSelectClient';
import i18n from 'i18n';

let { showToast } = useToast2.getState();
let { setPage } = useMobileCalendarStore.getState();

export let useAppointmentsStore = create((set, get) => ({
  specialist: {},
  appointment: {
    serviceId: '',
    date: '',
    time: '09:00',
    clientName: '',
    phone: '+380',
    comment: '',
  },
  appointments: [],
  specialistServices: [],

  groupAppointment: undefined,
  groupAppointments: [],
  allGroupAppointments: [],
  groupMembers: [],

  addAppointment: async () => {
    let client = useSelectClient.getState().client;
    if (!client) {
      showToast('e', i18n.t('select_or_add_client'));
      return;
    }

    set({
      appointment: {
        ...get().appointment,
        specialistId: checkAccess(),
        serviceIds: [get().appointment.serviceId],
        clientName: client.name.trimEnd(),
        phone: client.phone,
      },
    });

    if (get().appointment.date == '') {
      showToast('e', i18n.t('select_appointment_date'));
      return;
    }

    if (get().appointment.serviceId == '') {
      set({
        appointment: {
          ...get().appointment,
          serviceIds: [get().specialistServices[0]._id],
        },
      });
    }
    if (get().specialist.plan.expiresIn < Date.now()) {
      showToast('e', i18n.t('renew_license'));
      return;
    }

    let { data } = await axios.post(
      `${process.env.REACT_APP_API}/api/appointments`,
      get().appointment,
    );

    if (data == 'Слот зайнятий') {
      showToast('e', i18n.t('slot_occupied_by_another_appointment'));
      return;
    }

    if (data == 1) {
      set({ addAppointmentWindow: false });
      get().getSpecialistAppointments();
      showToast('s', i18n.t('appointment_added_successfully'));
      //Для мобайл
      setPage('appointments');
    }
  },

  getSpecialistAppointments: async () => {
    let groupAppointments = await API.GroupAppointment.getAll();
    set({ allGroupAppointments: groupAppointments });
    groupAppointments = groupAppointments.filter((groupTimeslot) =>
      groupTimeslot.date == moment().format('YYYY/MM/DD')
        ? new Date(`1970-01-01T${groupTimeslot.from}:00Z`) >
        new Date(`1970-01-01T${moment().format('HH:mm')}:00Z`)
        : groupTimeslot,
    );

    groupAppointments = groupAppointments.filter((groupAppointment) => {
      let dateAndTimeString = `${groupAppointment.date} ${groupAppointment.from}`;
      let momentObj = moment(dateAndTimeString, 'YYYY/MM/DD HH:mm');
      let timestamp = momentObj.valueOf();
      return timestamp > Date.now();
    });

    set({ groupAppointments });

    let { data } = await axios.get(
      `${process.env.REACT_APP_API
      }/api/specialists/${checkAccess()}/appointments`,
    );

    data = [...groupAppointments, ...data];

    data = sortByDateAndTime(data);
    data = removeOldReservations(data);
    data = groupByDate(data);

    console.log('appointments', data);
    set({ appointments: data });
  },

  cancelAppointment: async () => {
    let { data } = await axios.delete(
      `${process.env.REACT_APP_API}/api/appointments/${get().appointment._id}`,
    );
    if (data == 1) {
      set({ confirmDeleteWindow: false });
      get().getSpecialistAppointments();
      showToast('s', i18n.t('appointment_cancelled'));
      //для мобайл
      setPage('appointments');
    }
  },

  getSpecialistServices: async () => {
    let { data } = await axios.get(
      `${process.env.REACT_APP_API}/api/specialist_services/${checkAccess()}`,
      { withCredentials: true },
    );
    set({ specialistServices: data });
  },

  getSpecialistData: async () => {
    let { data } = await axios.get(
      `${process.env.REACT_APP_API}/api/specialists/${checkAccess()}`,
    );
    set({ specialist: data });
  },

  //! Вікна

  addAppointmentWindow: false,
  appointmentDetailsWindow: false,
  editAppointmentWindow: false,
  confirmDeleteWindow: false,
  groupAppointmentWindow: false,
  paymentServiceWindow: false,

  inputHandler: (e) => {
    if (e.target.id == 'phone' && e.target.value.length < 4) {
      return;
    }
    if (e.target.id == 'phone' && e.target.value.length > 13) {
      return;
    }
    if (e.target.id == 'date') {
      set({
        appointment: {
          ...get().appointment,
          [e.target.id]: moment(e.target.value).format('YYYY/MM/DD'),
        },
      });
      return;
    }

    set({
      appointment: { ...get().appointment, [e.target.id]: e.target.value },
    });
  },

  showAppointmentDetails: async (appointmentId) => {
    let { data } = await axios.get(
      `${process.env.REACT_APP_API}/api/appointments/${appointmentId}`,
    );
    set({ appointment: data, appointmentDetailsWindow: true });
    //для мобайл
    setPage('appointmentDetails');
  },

  showPaymentServiceWindow: async (state = true) => {
    set({
      paymentServiceWindow: state,
      appointmentDetailsWindow: false
    });
  },

  hideAppointmentDetailsWindow: () => {
    set({ appointmentDetailsWindow: false });
  },

  showConfirmDeleteWindow: () => {
    set({ appointmentDetailsWindow: false, confirmDeleteWindow: true });
  },

  hideConfirmDeleteWindow: () => {
    set({ confirmDeleteWindow: false });
  },

  showAddAppointmentWindow: () => {
    set({
      appointment: {
        serviceId: '',
        date: '',
        time: '09:00',
        clientName: '',
        phone: '+380',
        comment: '',
      },
    });

    useSelectClient.setState({
      client: undefined,
      clientSearchInputValue: undefined,
    });

    if (window.innerWidth < 1025) {
      setPage('addAppointment');
      return;
    }

    set({ addAppointmentWindow: true });
  },

  showAddAppointmentWindowForCalendar: (date, time) => {
    set({
      appointment: {
        serviceId: '',
        date: moment(date).format('YYYY-MM-DD'),
        time: time,
        clientName: '',
        phone: '+380',
        comment: '',
      },
    });

    useSelectClient.setState({
      client: undefined,
      clientSearchInputValue: undefined,
    });

    if (window.innerWidth < 1025) {
      setPage('addAppointment');
      return;
    }

    set({ addAppointmentWindow: true });
  },

  hideAddAppointmentWindow: () => {
    set({ addAppointmentWindow: false });
  },

  showEditAppointmentWindow: () => {
    set({ appointmentDetailsWindow: false, editAppointmentWindow: true });
  },

  hideEditAppointmentWindow: () => {
    set({ editAppointmentWindow: false });
  },

  updateAppointment: async () => {
    let client = useSelectClient.getState().client;
    if (!client) {
      showToast('e', i18n.t('select_or_add_client'));
      return;
    }

    set({
      appointment: {
        ...get().appointment,
        clientName: client.name,
        phone: client.phone,
      },
    });

    let { data } = await axios.put(
      `${process.env.REACT_APP_API}/api/appointments/${get().appointment._id}`,
      get().appointment,
    );
    if (data == 1) {
      get().getSpecialistAppointments();
      showToast('s', i18n.t('changes_updated'));
      if (window.innerWidth < 1025) {
        setPage('appointments');
        return;
      }
      set({ editAppointmentWindow: false });

      return;
    }

    if (data == 'Слот зайнятий') {
      showToast('e', i18n.t('slot_occupied_by_another_time'));
      return;
    }
  },

  showGroupAppointmentWindow: (groupAppointmentId) => {

    let [groupAppointment] = get().allGroupAppointments.filter(
      (el) => el._id == groupAppointmentId,
    );

    console.log('showGroupAppointmentWindow', groupAppointment);

    if (window.innerWidth < 1025) {
      setPage('groupAppointment');
    }

    set({
      groupAppointment,
      groupMembers: groupAppointment.participants,
      groupAppointmentWindow: true,
    });
  },

  changeGroupAppointmentWindowState: (state) => {
    set({ groupAppointmentWindow: state });
  },

  deleteGroupMember: (groupMemberId) => {
    let data = get().groupMembers.filter((el) => el._id != groupMemberId);
    set({ groupMembers: data });
  },

  deleteGroup: async (groupId, isSendSMS) => {
    let response = await API.GroupAppointment.deleteGroup(groupId, isSendSMS);
    if (response) {
      set({ groupAppointmentWindow: false });
      get().getSpecialistAppointments();

      showToast('s', i18n.t('deleted'));
    } else {
      showToast('e', i18n.t('delete_failed'));
    }
  },

  saveGroupMembers: async () => {
    let data = API.GroupAppointment.saveMembers({
      groupAppointmentId: get().groupAppointment._id,
      groupMembers: get().groupMembers,
    });
    if (data) {
      await get().getSpecialistAppointments();
      showToast('s', i18n.t('data_updated'));

      if (window.innerWidth < 1025) {
        setPage('appointments');
      }
      set({ groupAppointmentWindow: false });
    }
  },
}));

//Допоміжні

//Згрупувати записи по датам
function groupByDate(records) {
  // Create an empty object to store the groups
  const groups = {};
  // Iterate over the array of records
  for (const record of records) {
    // Check if the current date is already a key in the groups object
    if (!groups[record.date]) {
      // If it is not, add a new key for the current date and set the value to an array with the current record
      groups[record.date] = [record];
    } else {
      // If the date is already a key in the groups object, add the current record to the array of records for that date
      groups[record.date].push(record);
    }
  }
  // Convert the groups object to an array of objects with a date and records field
  const result = Object.entries(groups).map(([date, records]) => ({
    date,
    records,
  }));

  //sort
  result.sort((a, b) => new Date(a.date) - new Date(b.date));

  // Return the result
  return result;
}

function removeOldReservations(arr) {
  const currentDate = moment().format('x');
  let x = arr.filter((reservation) => {
    const reservationDate = moment(
      `${reservation.date} ${reservation.time}`,
    ).format('x');

    return reservationDate >= currentDate;
  });

  return x;
}

function sortByDateAndTime(data) {
  for (let i = 0; i < data.length; i++) {
    if (data[i].groupServiceId) {
      data[i].time = data[i].from;
      delete data[i].from;
    }
  }

  data = data.sort((a, b) => {
    let dateA = new Date(a.date + ' ' + a.time),
      dateB = new Date(b.date + ' ' + b.time);
    return dateA - dateB;
  });

  for (let i = 0; i < data.length; i++) {
    if (data[i].groupServiceId) {
      data[i].from = data[i].time;
      delete data[i].time;
    }
  }

  return data;
}
