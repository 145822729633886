import {Button} from '../../../../Components/BasicComponents/BasicComponents';
import stylesM from './PlanM.module.css';
import stylesD from './PlanD.module.css';
import {useTranslation} from 'react-i18next';

export function Plan({ isMobile, title, price, options, onClick, buttonText, isDevelopment = false }) {
  const { t } = useTranslation();

  const planStyles = isMobile ? stylesM : stylesD;
  const buttonStyle = {
    width: '100%',
    height: '30px',
    marginTop: 'auto',
    backgroundColor: isDevelopment ? 'grey' : undefined,
  };

  return (
    <div className={planStyles.plan_container}>
      <div className={planStyles.plan_title}>{title}</div>
      <div className={planStyles.options} style={{ fontSize: '18px', color: 'grey' }}>
        <span style={{ fontSize: '35px', fontWeight: 500, color: 'black' }}>
          {price}
        </span>{' '}
        {`${t('uah_per_month')}`}
      </div>
      <div className={planStyles.options}>
        {options.map((option, index) => (
          <div key={index}><p >· {option}</p> {index + 1 !== options.length ? <br/> : null} </div>
        ))}
      </div>
      <Button
        type={isDevelopment ? 5 : 4}
        name={t(buttonText)}
        style={buttonStyle}
        onClick={onClick}
      />
    </div>
  );
};