import {useEffect} from 'react';
import {Button, Input} from '../../../../Components/BasicComponents/BasicComponents';
import stylesD from './SMSTopUpD.module.css';
import stylesM from './SMSTopUpM.module.css';
import sms from '../../../../Imgs/sms.png';
import {useSMSTopUpStore} from '../../../../store';
import {useTranslation} from 'react-i18next';
import {LanguageEnum} from 'utils/enums';

export function SMSTopUp(props) {
  const { t, i18n } = useTranslation();
  const currentName = i18n.language === LanguageEnum.Uk ? 'грн' : 'UAH' 
  
  let {
    balance,
    getSMSbalance,
    topUpSMS,
    incrementPackage,
    decrementPackage,
    priceForSelectedAmountOfSMS,
    fee,
    sumToPay,
    createInvoice,
  } = useSMSTopUpStore();

  useEffect(() => {
    async function af() {
      getSMSbalance();
    }
    af();
  }, []);

  if (props.type == 'mobile') {
    return (
      <>
        <div className={stylesM.sms_topup_container}>
          <div className={stylesM.sms_topup_line1}>
            <div className={stylesM.sms_topup_img_container}>
              <img src={sms} alt="" />
            </div>
            <div className={stylesM.sms_topup_right_side}>
              <div className={stylesM.sms_topup_title}>SMS {t('notifications')}</div>
              <div className={stylesM.sms_topup_subtitle}>
                {t('top_up_balance_and_send_sms')}
              </div>
              <div className={stylesM.sms_topup_status}>{t('connected')}</div>
            </div>
          </div>

          <div className={stylesM.sms_topup_balance}>
            {t('your_balance')}: <span style={{ color: 'coral' }}>{balance} SMS</span>{' '}
          </div>

          <div className={stylesM.sms_topup_topup}>
            <div className={stylesM.sms_topup_topup_right_side}>
              <div className={stylesM.sms_topup_topup_right_side_title}>
                {t('top_up_balance')}
              </div>
              <div className={stylesM.sms_topup_topup_right_side_subtitle}>
                {t('enter_amount')} SMS
              </div>
            </div>

            <Input
              value={topUpSMS}
              onChange={() => false}
              style={{ width: '80px', fontWeight: 'bold', color: 'grey', padding: 0, textAlign: 'center' }}
            />

            <button className={stylesD.sms_topup_icn} type='button' onClick={incrementPackage}>+</button>
            <button className={stylesD.sms_topup_dec} type='button' onClick={decrementPackage}>-</button>
          </div>

          <div className={stylesM.sms_topup_info}>
            <div className={stylesM.sms_topup_info_cell}>
              {t('price_per')} {topUpSMS} SMS
            </div>
            <div className={stylesM.sms_topup_info_cell}>
              {priceForSelectedAmountOfSMS} {currentName}
            </div>
            <div className={stylesM.sms_topup_info_cell}>
              {t('bank_fee')}: 1.3%
            </div>
            <div className={stylesM.sms_topup_info_cell}>{fee} {currentName}</div>
            {/* <div className={stylesD.sms_topup_info_cell}>ПДВ (0 %):</div>
<div className={stylesD.sms_topup_info_cell}>0 грн</div> */}
            <div
              style={{ fontWeight: 700, fontSize: '16px', marginTop: '10px' }}
              className={stylesM.sms_topup_info_cell}
            >
              {t('amount_due')}:
            </div>
            <div
              style={{ fontWeight: 700, fontSize: '16px', marginTop: '10px' }}
              className={stylesM.sms_topup_info_cell}
            >
              {sumToPay} {currentName}
            </div>
          </div>

          <Button name={t('top_up')} onClick={createInvoice} />
        </div>
      </>
    );
  }

  return (
    <>
      <div className={stylesD.sms_topup_container}>
        <div className={stylesD.sms_topup_line1}>
          <div className={stylesD.sms_topup_img_container}>
            <img src={sms} alt="" />
          </div>
          <div className={stylesD.sms_topup_right_side}>
            <div className={stylesD.sms_topup_title}>SMS {t('notifications')}</div>
            <div className={stylesD.sms_topup_subtitle}>
              {t('top_up_balance_and_send_sms')}
            </div>
            <div className={stylesD.sms_topup_status}>{t('connected')}</div>
          </div>
        </div>

        <div className={stylesD.sms_topup_balance}>
          {t('your_balance')}: <span style={{ color: 'coral' }}>{balance} SMS</span>{' '}
        </div>

        <div className={stylesD.sms_topup_topup}>
          <div className={stylesD.sms_topup_topup_right_side}>
            <div className={stylesD.sms_topup_topup_right_side_title}>
              {t('top_up_balance')}
            </div>
            <div className={stylesD.sms_topup_topup_right_side_subtitle}>
              {t('enter_amount')} SMS
            </div>
          </div>

          <Input
            value={topUpSMS}
            onChange={() => false}
            style={{ width: '80px', fontWeight: 'bold', color: 'grey', padding: 0, textAlign: 'center' }}
          />

          <button className={stylesD.sms_topup_icn} type='button' onClick={incrementPackage}>+</button>
          <button className={stylesD.sms_topup_dec} type='button' onClick={decrementPackage}>-</button>
        </div>

        <div className={stylesD.sms_topup_info}>
          <div className={stylesD.sms_topup_info_cell}>
            {t('price_per')} {topUpSMS} SMS
          </div>
          <div className={stylesD.sms_topup_info_cell}>
            {priceForSelectedAmountOfSMS} {currentName} 
          </div>
          <div className={stylesD.sms_topup_info_cell}>{t('bank_fee')}: 3%</div>
          <div className={stylesD.sms_topup_info_cell}>{fee} {currentName}</div>
          {/* <div className={stylesD.sms_topup_info_cell}>ПДВ (0 %):</div>
          <div className={stylesD.sms_topup_info_cell}>0 грн</div> */}

          <div
            style={{ fontWeight: 700, fontSize: '16px', marginTop: '10px' }}
            className={stylesD.sms_topup_info_cell}
          >
            {t('amount_due')}:
          </div>
          <div
            style={{ fontWeight: 700, fontSize: '16px', marginTop: '10px' }}
            className={stylesD.sms_topup_info_cell}
          >
            {sumToPay} {currentName}
          </div>
        </div>

        <Button name={t('top_up')} onClick={createInvoice} />
      </div>
    </>
  );
}