import { create } from 'zustand';
import axios from 'axios';
import i18n from 'i18n';
import { checkAccess } from '../../access';
import { toastUtility } from 'utils/toast.utility';
import { useDB } from 'utils/DB';

export const usePaymentsStore = create((set, get) => ({
  selected: 1,
  sumWithoutDiscount: 0,
  discount: 0,
  discountInUAH: 0,
  total: 0,
  tariffs: [],
  currentTariff: null,
  isUpdatedTariffPlan: false,

  handleUpdatedTariffPlan: (state) => {
    set({ isUpdatedTariffPlan: state });
  },

  setCurrentTariff: (tariffId) => {
    const currentTariff = get().tariffs.find(({ _id }) => _id === tariffId);
    if (!currentTariff) return;

    set({
      currentTariff,
      sumWithoutDiscount: currentTariff.price,
      discount: 0,
      discountInUAH: 0,
      total: currentTariff.price,
    });
  },

  setSelected: (e) => {
    const selected = parseInt(e.target.id, 10);
    const { currentTariff } = get();

    if (!currentTariff) return;

    const discountData = currentTariff.discounts?.find((d) => d.period === selected) || { name: 0, period: 0 };
    const discountPercentage = parseFloat(discountData.name) || 0;

    const sumWithoutDiscount = selected * currentTariff.price;
    const discountInUAH = sumWithoutDiscount * (discountPercentage / 100);
    const total = sumWithoutDiscount - discountInUAH;

    set({
      selected,
      sumWithoutDiscount,
      discount: discountPercentage,
      discountInUAH,
      total,
    });
  },

  getTariffPlans: async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/api/payments/tariffs`);
      set({ tariffs: data });

      useDB.getState().updateDB('specialist');
    } catch (error) {
      console.error("Error fetching tariffs:", error);
    }
  },

  createInvoice: async () => {
    try {
      let { data } = await axios.post(
        `${process.env.REACT_APP_API}/api/payments/purchase-tariff`,
        {
          specialistId: checkAccess(),
          period: get().selected,
          tariffId: get().currentTariff._id,
          name: get().currentTariff.name[i18n.language]
        },
      );
      if (data.status) {
        window.open(data.pageUrl, '_self');
      }
    } catch (error) {
      toastUtility.showError(error.response.data.message ?? "Помилка створення інвойсу");
    }
  },
}));