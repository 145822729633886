import React, { useState, useLayoutEffect, useEffect } from 'react';
import {
  Background,
} from '../../Components/BasicComponents/BasicComponents';

import styles from './Support.module.css';
import BottomMenu from 'Components/BottomMenu/BottomMenu';
import Header, { MobileHeader } from 'Components/Header/Header';
import Imgs from 'Imgs/Imgs';
import Toast from 'Components/Toast/Toast';
import { checkAccess } from 'access';
import { useDB } from 'utils/DB';
import navigateImg from './Navigate.svg';
import managerImg from './Manager.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function Support() {
  const { t } = useTranslation();
  const { specialist } = useDB();
  const navigate = useNavigate();
  const [toast, setToast] = useState({ type: '', show: false, msg: '' });
  const specialistId = checkAccess();
  const isMobile = window.innerWidth < 1024;
  const tgLink = isMobile ? "tg://resolve?domain=planguin" : "https://t.me/+380937520309";
  const viberLink = isMobile ? "viber://chat?number=%2B380937520309" : "tel:+380937520309";

  function showToast(type, msg) {
    if (type == 'e') {
      type = 'error';
    }
    if (type == 's') {
      type = 'success';
    }
    setToast({ type, show: true, msg });
    setTimeout(() => {
      setToast({ type: '', show: false, msg: '' });
    }, 2000);
  }

  return (
    <>
      <Toast show={toast.show} type={toast.type} msg={toast.msg} />
      <Background style={{ backgroundColor: '#F4F7FE' }}>
        {
          isMobile 
            ? <MobileHeader />
            : <Header platform={'desktop'} specialistId={specialistId} />
        }
       
        <div className={styles.outer_container}>
          {
            isMobile && (
              <div className={styles.header_mobile}>
                <div className={styles.back_image}>
                  <Imgs
                    img={'back_arrow'}
                    onClickB={() => {
                      navigate(-1);
                    }}
                  />
                </div>
               
                <h3 className={styles.form_container_title}>
                  <img
                    src={managerImg}
                    alt="manager"
                  />
                  {t('support')}
                </h3>
              </div>
            ) 
          }

          <div className={styles.form_container}>
            <h4 className={styles.form_container_title}>{t('your_account_email')}</h4>
            <p className={styles.form_container_text_info}>{t('copy_email_button')}</p>
            <div className={styles.link_inner_container}>
              <div
                style={{ height: '40px' }}
                className={styles.link_container}
              >
                {specialist.email}
              </div>,
              <Imgs
                width={'50px'}
                height={'50px'}
                img={'copy'}
                onClickCopy={() => {
                  navigator.clipboard.writeText(specialist.email);
                  showToast('s', t('link_copied'));
                }}
              />
            </div>
          </div>
          <div className={`${styles.form_container} ${styles.form_navigate}`}>
            {
              !isMobile && (
                <h3 className={styles.form_container_title}>
                  <img
                    src={managerImg}
                    alt="manager"
                  />
                  {t('support')}
                </h3>
              ) 
            }
           
            <p className={styles.form_container_text_info}>
              {t('problems_occurred')} <br/> {t('contact_us')}
            
              <img
                src={navigateImg}
                alt="navigate"
              />
            </p>
            <a href={viberLink} target='_blank' className={`${styles.link} ${styles.link_purple}`} rel="noreferrer">Viber</a>
            <a href={tgLink} target='_blank' className={`${styles.link} ${styles.link_blue}`} rel="noreferrer">Telegram</a>
            <a href="https://wa.me/+380937520309" target='_blank' className={`${styles.link} ${styles.link_green}`} rel="noreferrer">WhatsApp </a>
            <a href="mailto:planguin.co@gmail.com" target='_blank' className={`${styles.link} ${styles.link_gray}`} rel="noreferrer">{t('write_to')} E-mail </a>
          </div>
        </div>

        {
          isMobile 
            ? <>
                <div style={{ height: '70px' }}></div>
                <BottomMenu />
              </>
            : null
        }
      </Background>
    </>
  );
}

export default Support;
